import * as React from "react";
import { useIconState } from "./useIconState";
export const createFluentIcon = (displayName, width, paths, options) => {
  const viewBoxWidth = width === "1em" ? "20" : width;
  const Icon = React.forwardRef((props, ref) => {
    const state = {
      ...useIconState(props, {
        flipInRtl: options === null || options === void 0 ? void 0 : options.flipInRtl
      }),
      ref,
      width,
      height: width,
      viewBox: `0 0 ${viewBoxWidth} ${viewBoxWidth}`,
      xmlns: "http://www.w3.org/2000/svg"
    };
    return React.createElement("svg", state, ...paths.map(d => React.createElement("path", {
      d,
      fill: state.fill
    })));
  });
  Icon.displayName = displayName;
  return Icon;
};