.headerSticky {
  /* Chromium browsers has partial support of sticky position for tables, */
  /* we don't use tables here so ignore this. */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  position: sticky;
  top: var(--screen-header-height);
  z-index: 1000;
  @apply bg-white;
}

.headerStatic {
  position: static;
}

.commandBar {
  border-bottom: 1px solid #edebe9;
}

.hidden {
  visibility: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-height: 0;
  overflow: auto;

  /* Let body to scroll in mobile */
  @apply mobile:overflow-visible mobile:flex-1-0-auto mobile:min-h-min;
}
