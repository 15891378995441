import { useIconContext } from "../contexts";
import { __styles, mergeClasses } from "@griffel/react";
const useRootStyles = __styles({
  "root": {
    "mc9l5x": "f1w7gpdv",
    "Bg96gwp": "fez10in",
    "ycbfsm": "fg4l7m0"
  },
  "rtl": {
    "Bz10aip": "f13rod7r"
  }
}, {
  "d": [".f1w7gpdv{display:inline;}", ".fez10in{line-height:0;}", ".f13rod7r{-webkit-transform:scaleX(-1);-moz-transform:scaleX(-1);-ms-transform:scaleX(-1);transform:scaleX(-1);}"],
  "t": ["@media (forced-colors: active){.fg4l7m0{forced-color-adjust:auto;}}"]
});
export const useIconState = (props, options) => {
  const {
    title,
    primaryFill = "currentColor",
    ...rest
  } = props;
  const state = {
    ...rest,
    title: undefined,
    fill: primaryFill
  };
  const styles = useRootStyles();
  const iconContext = useIconContext();
  state.className = mergeClasses(styles.root, (options === null || options === void 0 ? void 0 : options.flipInRtl) && (iconContext === null || iconContext === void 0 ? void 0 : iconContext.textDirection) === 'rtl' && styles.rtl, state.className);
  if (title) {
    state['aria-label'] = title;
  }
  if (!state['aria-label'] && !state['aria-labelledby']) {
    state['aria-hidden'] = true;
  } else {
    state['role'] = 'img';
  }
  return state;
};