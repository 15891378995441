.root {
  display: grid;
  padding: 24px;
  column-gap: 16px;
  row-gap: 20px;
}

.list {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: auto 1fr;
}

.autoRows {
  @apply grid-cols-[repeat(12,_80px)] auto-rows-auto tablet:grid-cols-12;
}
