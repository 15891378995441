.form {
  margin: 15px 0 0;
}

.boldLabel {
  margin: 0 8px 5px;
  font-weight: 700;
}

.label {
  margin: 0 8px 5px;
}

.codeEditor {
  height: 300px;
  border: 1px solid #eee;
  border-radius: 2px;
  margin: 0 8px 15px;
}

.textField {
  margin: 0 8px 15px;
}
