.root {
  @apply relative;
  @apply overflow-hidden;
  @apply transition-all;
}

.contentFlexColumn {
  @apply flex flex-col;
  @apply p-4;
  @apply gap-y-5;
}

.contentGrid {
  @apply grid grid-cols-8 auto-rows-auto;
  @apply p-4;
  @apply gap-x-4 gap-y-5;
}

.button {
  @apply absolute;
  @apply top-4 right-4;
}

.icon {
  @apply font-semibold;
}

.hide {
  @apply hidden;
}
