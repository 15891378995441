.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  /* Let body to scroll in mobile */
  @apply mobile:h-auto mobile:flex-1-0-auto;
}

.body {
  flex: 1 0 0;
  min-width: 0;
  min-height: 0;

  display: flex;
  flex-direction: row;

  /* Let body to scroll in mobile */
  @apply mobile:flex-1-0-auto mobile:min-h-min mobile:flex-col;
}

.nav {
  width: 260px;
  border: 1px solid #edebe9;
  /* Allow scrolling along y-axis */
  overflow-y: auto;
  overflow-x: hidden;

  @apply mobile:hidden;
}

.content {
  flex: 1 0 0;
  min-width: 0;
  min-height: 0;

  display: flex;
  flex-direction: column;

  /* Let body to scroll in mobile */
  @apply mobile:flex-1-0-auto mobile:min-h-min;
}
