.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  height: var(--screen-header-height);
  position: sticky;
  top: 0;
  z-index: 9999;
}

.mobileView {
  @apply hidden mobile:flex mobile:flex-row mobile:items-center mobile:text-white;
}

.desktopView {
  @apply mobile:hidden flex flex-row items-center text-white;
}

.headerMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--screen-header-height);
}

.headerArrow {
  padding-right: 10px;
  font-size: 14px;
}

.headerAppID {
  font-size: 12px;
  font-weight: 600;
}

.logoLink {
  display: flex;
}

.logo {
  height: 24px;
  margin: 12px 10px;
}

.logoNavHeader {
  height: 24px;
  margin: 12px;
}

.logoutButton {
  display: block;
  padding: 6px;
  margin: 6px;
  border-radius: 2px;
  background-color: orange;
}

.hamburger {
  margin: 8px 20px 8px 16px;
}

.links {
  @apply ml-auto mr-5 mobile:mr-4;
  @apply flex;
  @apply gap-10 tablet:gap-4;
}

.link {
  @apply inline-flex;
  @apply text-center;

  &:hover {
    text-decoration: none;
  }

  span {
    @apply text-white;
  }
}
