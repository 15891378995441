{
  "error.failed-to-initialize-app": "Failed to initialize the app. Please refresh this page to retry.",
  "system.name": "Authgear",
  "system.title": "Authgear Portal",
  "system.logo-uri": "/img/logo.png",
  "system.logo-inverted-uri": "/img/logo-inverted.png",

  "loading": "Loading...",

  "back": "Back",
  "error": "Error",
  "reset": "Reset",
  "discard-changes": "Discard Changes",
  "action": "Action",
  "ok": "OK",
  "done": "Done",
  "add": "Add",
  "adding": "Adding",
  "next": "Next",
  "edit": "Edit",
  "copy": "Copy",
  "close": "Close",
  "copied-to-clipboard": "Copied to clipboard",
  "save": "Save",
  "send": "Send",
  "saving": "Saving",
  "details": "Details",
  "activated": "Activated",
  "remove": "Remove",
  "delete": "Delete",
  "disable": "Disable",
  "reenable": "Re-enable",
  "enable": "Enable",
  "disabled": "Disabled",
  "enabled": "Enabled",
  "confirm": "Confirm",
  "continue": "Continue",
  "cancel": "Cancel",
  "connect": "Connect",
  "disconnect": "Disconnect",
  "verified": "Verified",
  "unverified": "Not Verified",
  "verify": "Verify",
  "make-as-verified": "Mark as verified",
  "make-as-unverified": "Mark as unverified",
  "create": "Create",
  "search": "Search",
  "apply": "Apply",
  "applying": "Applying",
  "activate": "Activate",
  "reveal": "Reveal",
  "download": "Download",
  "not-set": "Not set",
  "understood": "Understood",
  "dismiss": "Dismiss",
  "finish-editing": "Finish Editing",

  "apple": "Apple",
  "google": "Google",
  "facebook": "Facebook",
  "github": "GitHub",
  "linkedin": "LinkedIn",
  "adfs": "Microsoft AD FS",
  "azureadv2": "Microsoft Azure AD",
  "azureadb2c": "Microsoft Azure AD B2C",

  "oauth-provider.apple": "Apple",
  "oauth-provider.google": "Google",
  "oauth-provider.facebook": "Facebook",
  "oauth-provider.github": "GitHub",
  "oauth-provider.linkedin": "LinkedIn",
  "oauth-provider.adfs": "Microsoft AD FS",
  "oauth-provider.azureadv2": "Microsoft Azure AD",
  "oauth-provider.azureadb2c": "Microsoft Azure AD B2C",
  "oauth-provider.wechat": "Wechat",

  "login-id-key.username": "Username",
  "login-id-key.email": "Email",
  "login-id-key.phone": "Phone",

  "ScreenHeader.sign-out": "Sign Out",
  "ScreenHeader.cookie-preference": "Cookie Preference",
  "ScreenHeader.settings": "Account Settings",
  "ScreenHeader.links.schedule-demo": "Discuss your needs",
  "ScreenHeader.links.documentation": "Docs",

  "show-error.retry": "Retry",

  "errors.validation.unknown": "Invalid input at {location}: {kind}",
  "errors.validation.required": "Please fill in this field",
  "errors.validation.general": "Invalid value",
  "errors.validation.pattern": "Expect the value to match {expected}",
  "errors.validation.format": "{format, select, email{Please enter an email address} phone{Oops! That doesn't look like a valid phone number. Please re-enter.} username{Invalid username format} uri{Please enter an URL} wechat_account_id{Wechat account ID starts with gh_} http_origin_spec{An origin is a hostname without userinfo, path, query or fragment. Optionally it can start with http: or https: to enforce a scheme, otherwise HTTP or HTTPS is allowed} x_custom_attribute_pointer{Invalid attribute name} google_tag_manager_container_id{Google Tag Manager Container ID starts with GTM-} other{Invalid format}}",
  "errors.validation.format.duration.nonPositive": "Please enter a positive value",
  "errors.validation.minItems": "Expect at least {expected} items",
  "errors.validation.minItems.ui.phoneInput.allowList": "Please select at least {expected} country for the phone number settings",
  "errors.validation.uniqueItems": "Expect unique items in the list",
  "errors.validation.minimum": "Expect number larger than or equal to {minimum}",
  "errors.validation.maximum": "Expect number smaller than or equal to {maximum}",
  "errors.validation.minLength": "Expect string with length greater than or equal to {expected, plural, one{1 character} other{# characters}}",
  "errors.validation.maxLength": "Expect string with length less than or equal to {expected, plural, one{1 character} other{# characters}}",
  "errors.validation.noPrimaryAuthenticator": "Can't save the config. At least one authenticator for type {login_id_type, select, email {email} username {username} phone {phone} other {}} is needed.",
  "errors.validation.noSecondaryAuthenticator": "Can't save the config. At least one secondary authenticator is needed.",
  "errors.validation.blocked": "{reason, select, EmailDomainBlocklist{Email is not allowed} EmailDomainAllowlist{Email is not allowed} UsernameReserved{Username is not allowed} UsernameExcludedKeywords{Username is not allowed} PhoneNumberCountryCodeAllowlist{Phone number is from an unsupported region} other {}}",
  "errors.validation.passkey": "To use passkey, at least one Login ID must be activated",
  "errors.password-mismatch": "New password does not match with confirm password, please double check",
  "errors.password-policy.password-reused": "Password cannot be reused, please check password policies below",
  "errors.password-policy.containing-excluded-keywords": "Password contains excluded keywords, please check password policies below",
  "errors.password-policy.unknown": "Password is invalid; please check password policies",
  "errors.resource-too-large": "The {resourceType, select, favicon{favicon } app_logo{app logo } app_logo_dark{app logo } app_background_image{background image } other{}}file size is too large. Please upload a JPG, PNG or GIF file smaller than {maxSize} KB.",
  "errors.webhook.disallowed": "Operation is disallowed by one of your hook. Raw info: {code, react, children{{info}}}",
  "errors.webhook.timeout": "Operation is disallowed because the hook delivery resulted in a timeout. Make sure your hooks return a valid response in reasonable time.",
  "errors.webhook.invalid-response": "Operation is disallowed because one of your hook returned an invalid response. See {DocLink, react, href{https://docs.authgear.com/integrate/events-hooks} children{the documentation}} for details.",
  "errors.network": "There is a problem with the network",
  "errors.request-entity-too-large": "The file size is too large. Please upload a file that is within the size limit.",
  "errors.unsupported-image-file": "Image file of {type} is unsupported",
  "errors.unknown": "Unknown error: {message}",
  "error.client-quota-exceeded": "Application quota exceeded. To add more,{ReactRouterLink, react, children{upgrade your project plan} to{{to}}}.",
  "errors.invalid-selected-image": "The selected image is invalid. Try another one.",
  "errors.invariant.remove-last-identity": "Cannot remove the last identity",
  "errors.invariant.remove-last-primary-authenticator": "Cannot remove the last primary authenticator",
  "errors.invalid-address": "Please input a valid contract address.",
  "errors.invalid-token-id": "Please input a valid token ID.",
  "errors.duplicated-contract": "Duplicated contract address.",
  "errors.service-unavailable": "Service Unavailable. Please Try again later.",
  "errors.bad-nft-collection": "This NFT collection is either not supported or is from another network.",
  "errors.alchemy-protocol": "We have some problem with this NFT collection. Please contact us at {ExternalLink, react, children{hello@authgear.com} href{mailto:hello@authgear.com}}.",
  "errors.deno-hook.typecheck": "{pre, react, children{{message}}}",
  "errors.roles.key.validation.format": "Invalid role key. Only English alphabets, digits, colons \":\", and underscores \"_\" are accepted, and the first character must be an alphabet.",
  "errors.groups.key.validation.format": "Invalid group key. Only English alphabets, digits, colons \":\", and underscores \"_\" are accepted, and the first character must be an alphabet.",
  "errors.roles.key.duplicated": "This key is already used, please use a different key",
  "errors.groups.key.duplicated": "This key is already used, please use a different key",
  "errors.authenticator.not-found": "Authenticator not found",

  "warnings.free-plan": "To avoid spam content, free plans are limited to send 5 SMS and Whatsapp messsage by default. To be eligible for 100 free SMS/Whatsapp per mo, please complete{ExternalLink, react, href{https://go.authgear.com/portal-support} children{the application form}}.",

  "TodoButtonWrapper.dialog-title": "Feature Not Ready",
  "TodoButtonWrapper.dialog-message": "This feature is not yet implemented",

  "Toggle.on": "On",
  "Toggle.off": "Off",

  "ScreenNav.label": "Top-level tab navigation",
  "ScreenNav.all-projects": "All projects",
  "ScreenNav.getting-started": "Getting Started",
  "ScreenNav.analytics": "Analytics",
  "ScreenNav.user-management": "User Management",
  "ScreenNav.users": "Users",
  "ScreenNav.roles": "Roles",
  "ScreenNav.groups": "Groups",
  "ScreenNav.authentication": "Authentication",
  "ScreenNav.anonymous-users": "Anonymous Users",
  "ScreenNav.app2app": "App2App",
  "ScreenNav.web3": "Ethereum & NFT",
  "ScreenNav.biometric": "Biometric",
  "ScreenNav.mfa": "2FA",
  "ScreenNav.external-oauth": "Social / Enterprise Login",
  "ScreenNav.login-methods": "Login Methods",
  "ScreenNav.client-applications": "Applications",
  "ScreenNav.localization": "Email/SMS Templates",
  "ScreenNav.branding": "Branding",
  "ScreenNav.design": "Design",
  "ScreenNav.customText": "Custom Text",
  "ScreenNav.languages": "Languages",
  "ScreenNav.user-profile": "User Profile",
  "ScreenNav.standard-attributes": "Standard Attributes",
  "ScreenNav.custom-attributes": "Custom Attributes",
  "ScreenNav.bot-protection": "Bot Protection",
  "ScreenNav.hooks": "Hooks",
  "ScreenNav.billing": "Billing",
  "ScreenNav.advanced": "Advanced",
  "ScreenNav.audit-log": "Audit Log",
  "ScreenNav.admin-api": "Admin API",
  "ScreenNav.smtp": "Custom Email Provider",
  "ScreenNav.account-deletion": "Account Deletion",
  "ScreenNav.account-anonymization": "Account Anonymization",
  "ScreenNav.session": "Cookie Lifetime",
  "ScreenNav.integrations": "Integrations",

  "NavBreadcrumb.label": "Nested navigation",

  "AppsScreen.title": "Select Project",
  "AppsScreen.create-app": "Create Project",
  "AppsScreen.project-quota-reached": "You have reached the maximum number of projects in your account, please apply for an increase by completing{ExternalLink, react, href{https://go.authgear.com/portal-support} children{this form}}.",

  "UserGroupsList.column.name": "Name",
  "UserGroupsList.column.key": "Group Key",
  "UserGroupsList.column.role": "Role",
  "UserGroupsList.column.action": "Action",
  "UserGroupsList.actions.remove": "Remove",
  "UserGroupsList.empty": "No groups for this user",

  "DeleteUserGroupDialog.title": "Remove Group",
  "DeleteUserGroupDialog.description": "Are you sure you want to remove \"{userName}\" from the \"{groupName}\" group?",

  "GroupsScreen.title": "Groups",

  "GroupsList.column.name": "Name",
  "GroupsList.column.key": "Group Key",
  "GroupsList.column.description": "Description",
  "GroupsList.column.action": "Action",
  "GroupsList.delete-group": "Delete Group",
  "GroupsList.empty.search": "No results matched your search.",

  "GroupRolesList.column.name": "Name",
  "GroupRolesList.column.key": "Role Key",
  "GroupRolesList.column.action": "Action",
  "GroupRolesList.actions.remove": "Remove",
  "GroupRolesList.empty": "No roles in group",

  "DeleteGroupRoleDialog.title": "Remove Role",
  "DeleteGroupRoleDialog.description": "Are you sure you want to remove \"{groupName}\" from the \"{roleName}\" role?",

  "GroupDetailsScreen.roles.add": "Add Role",

  "AddGroupRolesDialog.title": "Add {groupName} group to role",
  "AddGroupRolesDialog.selectGroups": "Select Roles",

  "DeleteGroupDialog.title": "Delete Group",
  "DeleteGroupDialog.description": "Are you sure you want to delete the group \"{groupName}\"?",
  "DeleteGroupDialog.button.confirm": "Delete Group",

  "RolesScreen.title": "Roles",
  "RolesEmptyView.title": "You don't have any roles yet.",
  "RolesEmptyView.description": "Create roles to represent the types of users that access your applications.",
  "RolesEmptyView.button.text": "Create Role",

  "RoleDetailsScreen.tabs.settings": "Settings",
  "RoleDetailsScreen.tabs.groups": "Groups",
  "RoleDetailsScreen.button.deleteRole": "Delete Role",
  "RoleDetailsScreen.groups.add": "Add to Group",

  "GroupDetailsScreen.tabs.settings": "Settings",
  "GroupDetailsScreen.tabs.roles": "Roles",

  "AddRoleGroupsDialog.title": "Add {roleName} role to group",
  "AddRoleGroupsDialog.selectGroups": "Select Groups",

  "GroupsEmptyView.title": "You don't have any groups yet.",
  "GroupsEmptyView.description": "Create groups to represent the types of users that access your applications. Users in a group inherit the same set of roles.",
  "GroupsEmptyView.button.text": "Create Group",

  "AddRoleScreen.title": "Create Role",
  "AddRoleScreen.roleKey.title": "Role Key",
  "AddRoleScreen.roleKey.description": "The Role Key will be included in the UserInfo and JWT token of the user. e.g. app_a_admin.",
  "AddRoleScreen.roleName.title": "Name",
  "AddRoleScreen.roleName.description": "A display name of this role in the portal.",
  "AddRoleScreen.roleDescription.title": "Description",

  "AddGroupScreen.title": "Create Group",
  "AddGroupScreen.groupName.title": "Name",
  "AddGroupScreen.groupName.description": "A display name of this group in the portal.",
  "AddGroupScreen.groupKey.title": "Group Key",
  "AddGroupScreen.groupKey.description": "The Group Key is a unique identifier for this group when referenced from Admin API and Hooks.",
  "AddGroupScreen.groupDescription.title": "Description",

  "GroupDetailsSettingsForm.groupName.title": "Name",
  "GroupDetailsSettingsForm.groupName.description": "A display name of this group in the portal.",
  "GroupDetailsSettingsForm.groupKey.title": "Group Key",
  "GroupDetailsSettingsForm.groupKey.description": "The Group Key is a unique identifier for this group when referenced from Admin API and Hooks.",
  "GroupDetailsSettingsForm.groupDescription.title": "Description",
  "GroupDetailsSettingsForm.button.deleteGroup": "Delete Group",

  "RolesList.column.name": "Name",
  "RolesList.column.key": "Role Key",
  "RolesList.column.description": "Description",
  "RolesList.column.action": "Action",
  "RolesList.delete-role": "Delete Role",
  "RolesList.empty.search": "No results matched your search.",

  "RoleGroupsList.column.name": "Name",
  "RoleGroupsList.column.key": "Group Key",
  "RoleGroupsList.column.action": "Action",
  "RoleGroupsList.actions.remove": "Remove",
  "RoleGroupsList.empty": "No groups in role",

  "DeleteRoleGroupDialog.title": "Remove Group",
  "DeleteRoleGroupDialog.description": "Are you sure you want to remove \"{roleName}\" from the \"{groupName}\" group?",

  "DeleteRoleDialog.title": "Delete Role",
  "DeleteRoleDialog.description": "Are you sure you want to delete the role \"{roleName}\"?",
  "DeleteRoleDialog.button.confirm": "Delete Role",

  "UserRolesList.column.name": "Name",
  "UserRolesList.column.key": "Role Key",
  "UserRolesList.column.group": "Group",
  "UserRolesList.column.action": "Action",
  "UserRolesList.actions.remove": "Remove",
  "UserRolesList.empty": "No roles for this user",

  "DeleteUserRoleDialog.title": "Remove Role",
  "DeleteUserRoleDialog.description": "Are you sure you want to remove \"{roleName}\" from the user \"{userName}\"?",

  "UsersScreen.title": "Users",
  "UsersScreen.add-user": "Create User",
  "UsersScreen.search.resultLimited": "Only showing top 50 results, please refine the search query if you can't find what you want.",
  "UsersScreen.filters.groups.placeholder": "Groups",
  "UsersScreen.filters.roles.placeholder": "Roles",

  "AddUserScreen.title": "Create User",
  "AddUserScreen.user-info.label": "Select a method",
  "AddUserScreen.password.label": "Password",
  "AddUserScreen.add-user.label": "Add User",
  "AddUserScreen.cannot-add-user": "Please enable at least one of username, email or phone in authentication screen",
  "AddUserScreen.error.invalid-password": "Please check password policies below",
  "AddUserScreen.error.duplicated-identity": "Duplicated identity",
  "AddUserScreen.passkey-only.message": "User must be created using their own devices when passkey is the only way to login.",
  "AddUserScreen.password-creation-type.manual": "Enter a password",
  "AddUserScreen.password-creation-type.auto": "Automatically create a password and send to the user",
  "AddUserScreen.force-change-on-login": "Ask user to change password on login",
  "AddUserScreen.send-password": "Send the password to user's email",

  "UserDetailsScreen.title": "User Details",
  "UserDetailsScreen.remove-user": "Remove User",
  "UserDetailsScreen.anonymize-user": "Anonymize User",
  "UserDetailsScreen.reenable-user": "Re-enable User",
  "UserDetailsScreen.disable-user": "Disable User",
  "UserDetailsScreen.cancel-removal": "Cancel Removal",
  "UserDetailsScreen.cancel-anonymization": "Cancel Anonymization",
  "UserDetailsScreen.scheduled-deletion": "This user is disabled and scheduled to be removed on {date}",
  "UserDetailsScreen.scheduled-anonymization": "This user is disabled and scheduled to be anonymized on {date}",
  "UserDetailsScreen.user-account-security.siwe-enabled": "This tab is not available when the user signs in with Ethereum.",
  "UserDetailsScreen.user-anonymized": "User data erased",
  "UserDetailsScreen.user-anonymized.message": "This user is anonymized and all data are erased.",
  "UserDetailsScreen.groups.add": "Add to Group",
  "UserDetailsScreen.roles.add": "Add Roles",

  "AddUserGroupsDialog.title": "Add the user {userName} to the group",
  "AddUserGroupsDialog.selectGroups": "Select Groups",

  "AddUserRolesDialog.title": "Add roles to the user {userName}",
  "AddUserRolesDialog.selectRoles": "Select Roles",

  "UsersList.column.raw-id": "ID",
  "UsersList.column.username": "Username",
  "UsersList.column.email": "Email",
  "UsersList.column.phone": "Phone",
  "UsersList.column.roles": "Role",
  "UsersList.column.groups": "Group",
  "UsersList.column.signed-up": "Signed Up",
  "UsersList.column.last-login-at": "Last Login",
  "UsersList.disable-user": "Disable User",
  "UsersList.reenable-user": "Re-enable User",
  "UsersList.cancel-removal": "Cancel Removal",
  "UsersList.cancel-anonymization": "Cancel Anonymization",
  "UsersList.anonymous-user": "Anonymous User",
  "UsersList.anonymized-user": "User data erased",
  "UsersList.empty.normal": "No users yet.",
  "UsersList.empty.search": "No results matched your search.",

  "SetUserDisabledDialog.disable-user.title": "Disable User",
  "SetUserDisabledDialog.disable-user.description": "Do you really want to disable user {username}? Disabling the user {username} will forbid them from logging in. Their existing sessions will also be removed.",
  "SetUserDisabledDialog.reenable-user.title": "Re-enable User",
  "SetUserDisabledDialog.reenable-user.description": "Do you really want to re-enable user {username}? Re-enable the user {username} so that they can login again.",
  "SetUserDisabledDialog.cancel-removal.title": "Cancel Scheduled Removal",
  "SetUserDisabledDialog.cancel-removal.description": "Do you really want to cancel scheduled removal on user {username}? If the removal is canceled, they will be re-enabled and can login again.",
  "SetUserDisabledDialog.cancel-removal.label": "Cancel Removal",
  "SetUserDisabledDialog.cancel-anonymization.title": "Cancel Scheduled Anonymization",
  "SetUserDisabledDialog.cancel-anonymization.description": "Do you really want to cancel scheduled anonymization on user {username}? If the removal is canceled, they will be re-enabled and can login again.",
  "SetUserDisabledDialog.cancel-anonymization.label": "Cancel Anonymization",

  "DeleteUserDialog.title": "Remove User",
  "DeleteUserDialog.text": "Are you really want to remove user {username}? This action cannot be undone.",
  "DeleteUserDialog.label.remove-immediately": "Remove Immediately",
  "DeleteUserDialog.label.schedule-removal": "Schedule Removal",

  "AnonymizeUserDialog.title": "Anonymize User",
  "AnonymizeUserDialog.text": "Are you really want to anonymize user {username}? This action cannot be undone.",
  "AnonymizeUserDialog.label.remove-immediately": "Anonymize Immediately",
  "AnonymizeUserDialog.label.schedule-removal": "Schedule Anonymization",

  "UserDetails.signed-up": "Signed Up: {datetime}",
  "UserDetails.last-login-at": "Last Login: {datetime}",

  "UserDetails.command-bar.login-as-user": "Login as this user",
  "UserDetails.command-bar.invalidate-sessions": "Invalidate all sessions",

  "UserDetails.user-profile.header": "Profile",

  "UserDetails.account-security.header": "Account Security",
  "UserDetails.account-security.last-updated": "Last updated at {datetime}",
  "UserDetails.account-security.added-on": "Added: {datetime}",
  "UserDetails.account-security.change-password": "Change Password",
  "UserDetails.account-security.primary": "Primary Authenticators",
  "UserDetails.account-security.secondary": "2-Factor Authenticators",
  "UserDetails.account-security.secondary.add": "Add 2FA",
  "UserDetails.account-security.secondary.empty": "No 2-Factor Authenticator yet.",
  "UserDetails.account-security.secondary.empty-but-required": "No 2-Factor Authenticator yet. The user cannot login.",
  "UserDetails.account-security.secondary.empty-but-within-grace-period": "No 2-Factor Authenticator yet. The user can login and setup 2FA before {gracePeriodEndAt}.",
  "UserDetails.account-security.remove-confirm-dialog.title": "Remove Authenticator",
  "UserDetails.account-security.remove-confirm-dialog.message": "Are you sure you want to remove authenticator {displayName}",
  "UserDetails.account-security.change-on-login.label": "Ask to change password on login",
  "UserDetails.account-security.change-on-login.description": "The user will be asked to change password at the next login",
  "UserDetails.account-security.expired": "{Text, react, className{{prefixClassName}} children{Password Expired: }} The password is expired for {expiredInDays} days. The user will be asked to update it on their next login. ",
  "UserDetails.account-security.mark-as-expired-confirm-dialog.title": "Change on Login",
  "UserDetails.account-security.mark-as-expired-confirm-dialog.message": "The user will be asked to change password at the next login.",
  "UserDetails.account-security.mark-as-expired-confirm-dialog.message.revoke": "The user will NOT be asked to change password at the next login.",
  "UserDetails.account-security.secondary.grant-grace-period": "Grant grace period to set up 2FA",
  "UserDetails.account-security.secondary.update-existing-grace-period": "{Extend, react} or {Cancel, react}",
  "UserDetails.account-security.secondary.extend-grace-period": "Extend grace period",
  "UserDetails.account-security.secondary.cancel-grace-period": "Cancel grace period",
  "UserDetails.account-security.grant-mfa-grace-period-confirm-dialog.title": "Grace Period for 2FA Setup",
  "UserDetails.account-security.grant-mfa-grace-period-confirm-dialog.message": "The user will have a 10-day grace period to log in and set up 2FA.",
  "UserDetails.account-security.extend-mfa-grace-period-confirm-dialog.title": "Extend Grace Period",
  "UserDetails.account-security.extend-mfa-grace-period-confirm-dialog.message": "The user will have an additional 10 days to log in and set up 2FA.",
  "UserDetails.account-security.cancel-mfa-grace-period-confirm-dialog.title": "Cancel Grace Period",
  "UserDetails.account-security.cancel-mfa-grace-period-confirm-dialog.message": "The user will not be able to log in and set up 2FA.",

  "UserDetails.disable-delete.header": "Disable/Delete",

  "UserDetails.disabled.badge": "Disabled",
  "UserDetails.scheduled-anonymization.badge": "Anonymization scheduled",
  "UserDetails.anonymized.badge": "Anonymized",
  "UserDetails.scheduled-removal.badge": "Removal scheduled",

  "UserDetailsAdminActions.title": "Admin Actions",
  "UserDetailsAdminActions.disable-user.title": "Disable User",
  "UserDetailsAdminActions.disable-user.body": "Temporarily disables the user's account, preventing them from logging in or using the system.",
  "UserDetailsAdminActions.disable-user.action.disable": "Disable user",
  "UserDetailsAdminActions.disable-user.action.enable": "Enable user",
  "UserDetailsAdminActions.anonymize-user.title": "Anonymize User",
  "UserDetailsAdminActions.anonymize-user.body": "Delete all data of this user, only the user ID is retained.",
  "UserDetailsAdminActions.anonymize-user.action.anonymize": "Anonymize user",
  "UserDetailsAdminActions.anonymize-user.action.cancel": "Cancel anonymization",
  "UserDetailsAdminActions.remove-user.title": "Remove User",
  "UserDetailsAdminActions.remove-user.body": "Permanently deletes the user's account and all related data. This action is irreversible.",
  "UserDetailsAdminActions.remove-user.action.remove": "Remove user",
  "UserDetailsAdminActions.remove-user.action.cancel": "Cancel removal",

  "Add2FAScreen.title.phone": "Add 2FA Phone",
  "Add2FAScreen.title.email": "Add 2FA Email",
  "Add2FAScreen.title.password": "Add 2FA Password",
  "Add2FAScreen.email.label": "Email",
  "Add2FAScreen.password.label": "Password",
  "Add2FAScreen.error.duplicated-phone-number": "2FA phone number already exist",
  "Add2FAScreen.error.duplicated-email": "2FA email already exist",

  "ChangePasswordScreen.title": "Change Password",
  "ChangePasswordScreen.email": "Email",
  "ChangePasswordScreen.password-creation-type.manual": "Enter a password",
  "ChangePasswordScreen.password-creation-type.auto": "Automatically create a password and send to the user",
  "ChangePasswordScreen.new-password": "Password",
  "ChangePasswordScreen.force-change-on-login": "Ask user to change password on login",
  "ChangePasswordScreen.send-password": "Send the password to user's email",
  "ChangePasswordScreen.error.fetch-password-policy": "Failed to fetch password policy",
  "ChangePasswordScreen.error.send-password-no-target": "No email identity found",
  "ChangePasswordScreen.change": "Change",

  "UserDetails.connected-identities.header": "Identities",
  "UserDetails.connected-identities.title": "Identities",
  "UserDetails.connected-identities.add-identity": "Add Identity",
  "UserDetails.connected-identities.oauth": "Social",
  "UserDetails.connected-identities.email": "Email",
  "UserDetails.connected-identities.phone": "Phone",
  "UserDetails.connected-identities.username": "Username",
  "UserDetails.connected-identities.biometric": "Biometric",
  "UserDetails.connected-identities.biometric.unknown-device": "Unknown Device",
  "UserDetails.connected-identities.anonymous": "Anonymous",
  "UserDetails.connected-identities.siwe": "Ethereum Wallet",
  "UserDetails.connected-identities.siwe.nft-collections.title": "NFTs",
  "UserDetails.connected-identities.siwe.nft-collections.name": "{name} ({address})",
  "UserDetails.connected-identities.siwe.nft-collections.token-id": "Token ID",
  "UserDetails.connected-identities.siwe.nft-collections.transaction-hash": "Txn Hash",
  "UserDetails.connected-identities.siwe.nft-collections.balance": "Balance",
  "UserDetails.connected-identities.siwe.nft-collections.timestamp": "Timestamp",
  "UserDetails.connected-identities.siwe.nft-collections.block": "Block",
  "UserDetails.connected-identities.siwe.nft-collections.view-tokens": "View tokens",
  "UserDetails.connected-identities.anonymous.anonymous-user": "Anonymous User",
  "UserDetails.connected-identities.ldap": "LDAP",

  "UserDetails.connected-identities.connected-on": "Connected on {datetime}",
  "UserDetails.connected-identities.added-on": "Added on {datetime}",
  "UserDetails.connected-identities.primary-identities.title": "Primary Identities",
  "UserDetails.connected-identities.primary-email": "Primary Email",
  "UserDetails.connected-identities.primary-phone": "Primary Phone",
  "UserDetails.connected-identities.primary-username": "Primary Username",

  "UserDetails.connected-identities.confirm-remove-identity-title": "Remove Identity",
  "UserDetails.connected-identities.confirm-remove-identity-message": "Are you sure you want to remove identity {identityName}",

  "UserProfileForm.standard-attributes.title": "Standard Attributes",
  "UserProfileForm.custom-attributes.title": "Custom Attributes",
  "UserProfileForm.picture.placeholder": "URL to the profile picture",
  "UserProfileForm.profile.placeholder": "URL to the profile webpage",
  "UserProfileForm.website.placeholder": "URL to the personal website",
  "UserProfileForm.gender.other.label": "Others (Please specify)",

  "CreateIdentity.require-login-id": "You need to add \"login_id\" to identities list in authgear config",

  "EmailScreen.add.title": "Add Email",
  "EmailScreen.edit.title": "Edit Email",
  "EmailScreen.email.label": "Email",
  "EmailScreen.error.duplicated-email": "Email already exist",
  "EmailScreen.edit.current-value": "Current email: {value}",

  "PhoneScreen.add.title": "Add Phone",
  "PhoneScreen.edit.title": "Edit Phone",
  "PhoneScreen.phone.label": "Phone Number",
  "PhoneScreen.country-code.label": "Country Code",
  "PhoneScreen.error.duplicated-phone-number": "Phone number already exist",
  "PhoneScreen.edit.current-value": "Current phone: {value}",

  "UsernameScreen.add.title": "Add Username",
  "UsernameScreen.edit.title": "Edit Username",
  "UsernameScreen.username.label": "Username",
  "UsernameScreen.password.label": "Password",
  "UsernameScreen.error.duplicated-username": "Username already exist",
  "UsernameScreen.edit.current-value": "Current username: {value}",

  "EditPictureScreen.title": "Edit Profile Picture",
  "EditPictureScreen.upload-new-picture.label": "Upload New Picture",
  "EditPictureScreen.remove-picture.label": "Remove Picture",
  "EditPictureScreen.remove-picture.dialog.description": "Are you sure you want to remove the profile picture?",

  "UserDetails.groups.header": "Groups",
  "UserDetails.roles.header": "Roles",

  "UserDetails.session.header": "Sessions and apps",
  "UserDetails.session.devices": "Devices",
  "UserDetails.session.clientID": "Application",
  "UserDetails.session.clientID.tooltip.message": "Client ID: {clientID}",
  "UserDetails.session.last-activity": "Last Activity",
  "UserDetails.session.ip-address": "IP Address",
  "UserDetails.session.devices.unknown": "Unknown",
  "UserDetails.session.action": "Action",
  "UserDetails.session.action.revoke": "Terminate",
  "UserDetails.session.revoke-all": "Terminate all sessions",
  "UserDetails.session.confirm-dialog.revoke.title": "Terminate session",
  "UserDetails.session.confirm-dialog.revoke.message": "Are you sure to terminate this session of the user?",
  "UserDetails.session.confirm-dialog.revoke-all.title": "Terminate all sessions",
  "UserDetails.session.confirm-dialog.revoke-all.message": "Are you sure to terminate all sessions of the user?",
  "UserDetails.session.revoke-error.generic": "Failed to terminate session.",
  "UserDetails.session.empty": "No sessions yet.",

  "UserDetails.authorization.header": "Authorized apps",
  "UserDetails.authorization.client-name": "App name",
  "UserDetails.authorization.scopes": "Permission",
  "UserDetails.authorization.scopes.full-userinfo": "Has access to full profile",
  "UserDetails.authorization.created-at": "Created at",
  "UserDetails.authorization.action": "Action",
  "UserDetails.authorization.action.remove": "Remove",
  "UserDetails.authorization.remove-error.generic": "Failed to remove authorized app.",
  "UserDetails.authorization.confirm-dialog.remove.title": "Remove authorized app",
  "UserDetails.authorization.confirm-dialog.remove.message": "Are you sure to remove the authorized app?",
  "UserDetails.authorization.empty": "No authorized apps yet.",

  "LoginIDConfigurationScreen.title": "Login IDs",
  "LoginIDConfigurationScreen.columns.order": "Order",
  "LoginIDConfigurationScreen.columns.orderTooltipMessage": "Higher priority Login ID will be shown first. E.g. if you enable both phone and email as Login ID and phone have higher priority, it will show Phone number first in login and signup screen",
  "LoginIDConfigurationScreen.error.calling-code-min-items": "At least one country calling code is needed for Phone Numbers",
  "LoginIDConfigurationScreen.email.create-disabled": "Forbid the user to add email after signup",
  "LoginIDConfigurationScreen.email.update-disabled": "Forbid the user to update email after signup",
  "LoginIDConfigurationScreen.email.delete-disabled": "Forbid the user to delete email after signup",
  "LoginIDConfigurationScreen.email.title": "Email Address",
  "LoginIDConfigurationScreen.email.caseSensitive": "Case Sensitive",
  "LoginIDConfigurationScreen.email.ignoreDotLocal": "Ignore dot (.) in local part (e.g. john.doe@acme.com = johndoe@acme.com)",
  "LoginIDConfigurationScreen.email.blockPlus": "Disallow plus (+) in local part (e.g. john+app1@gmail.com)",
  "LoginIDConfigurationScreen.email.blockPlusTooltipMessage": "Some email provider use \"+\" as an alias for multiple email forwarding to the same address. Enable this option if you want a stricter uniqueness for users per email account.",
  "LoginIDConfigurationScreen.email.domainBlocklist": "Block domains in blocklist",
  "LoginIDConfigurationScreen.email.domainBlocklistTooltipMessage": "Require domain allowlist disabled. {br, react} {br, react} To enable domain blocklist, you will need to input email domains that you want to block in the below text field. (e.g. example.com)",
  "LoginIDConfigurationScreen.email.blockFreeEmailProviderDomains": "Block email addresses from free email provider domains",
  "LoginIDConfigurationScreen.email.blockFreeEmailProviderDomainsTooltipMessage": "Require domain blocklist enabled",
  "LoginIDConfigurationScreen.email.domainAllowlist": "Allow domains in allowlist only",
  "LoginIDConfigurationScreen.email.domainAllowlistTooltipMessage": "Require domain blocklist disabled. {br, react} {br, react} To enable domain allowlist, you will need to input email domains that you want to allow in the below text field. (e.g. example.com)",
  "LoginIDConfigurationScreen.username.title": "Username",
  "LoginIDConfigurationScreen.username.create-disabled": "Forbid the user to add username after signup",
  "LoginIDConfigurationScreen.username.update-disabled": "Forbid the user to update username after signup",
  "LoginIDConfigurationScreen.username.delete-disabled": "Forbid the user to delete username after signup",
  "LoginIDConfigurationScreen.username.blockReservedUsername": "Block Reserved Username",
  "LoginIDConfigurationScreen.username.excludeKeywords": "Exclude Keyword",
  "LoginIDConfigurationScreen.username.excludeKeywordsTooltipMessage": "To enable exclude keywords, you will need to input the keywords that you want to block in the below text field.",
  "LoginIDConfigurationScreen.username.caseSensitive": "Case Sensitive",
  "LoginIDConfigurationScreen.username.asciiOnly": "ASCII Only",
  "LoginIDConfigurationScreen.phone.title": "Phone Number",
  "LoginIDConfigurationScreen.phone.preselect-by-ip": "Pre-select the country calling code by the IP address of the user",
  "LoginIDConfigurationScreen.phone.create-disabled": "Forbid the user to add phone number after signup",
  "LoginIDConfigurationScreen.phone.update-disabled": "Forbid the user to update phone number after signup",
  "LoginIDConfigurationScreen.phone.delete-disabled": "Forbid the user to delete phone number after signup",
  "LoginIDConfigurationScreen.phone.columns.active": "Active",
  "LoginIDConfigurationScreen.phone.columns.country-or-area": "Country / Area",
  "LoginIDConfigurationScreen.phone.columns.code": "Code",
  "LoginIDConfigurationScreen.phone.columns.order": "Order",
  "LoginIDConfigurationScreen.phone.columns.pinned": "Pinned for Selection",
  "LoginIDConfigurationScreen.phone.default-order": "Default (ascending)",

  "calling-code-area.gmss": "Global Mobile Satellite System",
  "calling-code-area.international": "International Networks",

  "AuthenticatorConfigurationScreen.columns.activate": "Activate",
  "AuthenticatorConfigurationScreen.columns.authenticator": "Authenticator",

  "Web3ConfigurationScreen.title": "Ethereum & NFT",
  "Web3ConfigurationScreen.description": "Authenticate the user by crypto wallets and NFT ownership. Their wallet addresses and the list of NFTs owned will be available as user info.",
  "Web3ConfigurationScreen.siwe.title": "Login with Ethereum",
  "Web3ConfigurationScreen.siwe.description": "Add a button to the signup/login screen for the users to login with their crypto wallet such as MetaMask. Enabling this will replace all other login methods. See the {DocLink, react, href{https://docs.authgear.com/strategies/web3#platform-support} children{list of supported wallets here.}}",
  "Web3ConfigurationScreen.network-droplist.label": "Network",
  "Web3ConfigurationScreen.collection-list.title": "NFT Collections",
  "Web3ConfigurationScreen.collection-list.status.pending": "Pending",
  "Web3ConfigurationScreen.collection-list.status.indexing": "Indexing",
  "Web3ConfigurationScreen.colleciton-list.action.remove": "Remove",
  "Web3ConfigurationScreen.colleciton-list.action.details": "Details",
  "Web3ConfigurationScreen.collection-list.description": "Add your collection contracts here. Authgear will monitor them and verify the ownership for your users.",
  "Web3ConfigurationScreen.collection-list.add-collection": "Add Collection",
  "Web3ConfigurationScreen.collection-list.add-collection.contract-address": "Contract Address",
  "Web3ConfigurationScreen.collection-list.add-collection.placeholder": "e.g. 0x06012c8cf97bead5deae237070f9587f8e7a266d",
  "Web3ConfigurationScreen.collection-list.add-collection.metadata-error": "Failed to fetch metadata for contract",
  "Web3ConfigurationScreen.collection-list.add-collection.token-type-button.erc721.title": "ERC-721",
  "Web3ConfigurationScreen.collection-list.add-collection.token-type-button.erc721.description": "Non-fungible Token standard",
  "Web3ConfigurationScreen.collection-list.add-collection.token-type-button.erc1155.title": "ERC-1155",
  "Web3ConfigurationScreen.collection-list.add-collection.token-type-button.erc1155.description": "Multiple Token Standard",
  "Web3ConfigurationScreen.collection-list.add-collection.large-collection-dialog.title": "This collection is not supported yet",
  "Web3ConfigurationScreen.collection-list.add-collection.large-collection-dialog.description": "The size of the collection you entered is too large. Please contact us if this is the contract you want to use it in your project.",
  "Web3ConfigurationScreen.collection-list.add-collection.large-collection-dialog.contact-sales": "Contact sales",
  "Web3ConfigurationScreen.collection-list.add-collection.toke-tracking-dialog.title": "Which tokens should we track?",
  "Web3ConfigurationScreen.collection-list.add-collection.toke-tracking-dialog.description": "Enter the token IDs of the NFTs you want to track and report in the user info. Type in a new line for each item.",
  "Web3ConfigurationScreen.collection-list.add-collection.toke-tracking-dialog.placeholder": "One token ID per each line",
  "Web3ConfigurationScreen.collection-list.add-collection.toke-tracking-dialog.item-count": "{count}/{max} items",

  "Web3ConfigurationScreen.confirmation-dialog.title": "Confirm Changes?",
  "Web3ConfigurationScreen.confirmation-dialog.siwe-enabled.title": "Enabling Login with Ethereum",
  "Web3ConfigurationScreen.confirmation-dialog.siwe-enabled.description": "This will replace all other login methods. Users cannot login with email, phone, username or social / enterprise connections. All your current authentication settings will be lost.",
  "Web3ConfigurationScreen.confirmation-dialog.siwe-disabled.title": "Disabling Login with Ethereum",
  "Web3ConfigurationScreen.confirmation-dialog.siwe-disabled.description": "This will set your login methods to the default values.",
  "Web3ConfigurationScreen.confirmation-dialog.collection-added.title": "Adding NFT Collections",
  "Web3ConfigurationScreen.confirmation-dialog.collection-added.description": "The following collections will be added to your project.",
  "Web3ConfigurationScreen.confirmation-dialog.collection-removed.title": "Removing NFT Collections",
  "Web3ConfigurationScreen.confirmation-dialog.collection-removed.description": "The following collections will be removed from your project.",
  "Web3ConfigurationScreen.confirmation-dialog.tracked-token-changed.title": "Changing Tracked Tokens",
  "Web3ConfigurationScreen.confirmation-dialog.tracked-token-changed.description": "The following collections will have their tracked tokens updated.",
  "Web3ConfigurationScreen.confirmation-dialog.network-changed.title": "Changing Network",
  "Web3ConfigurationScreen.confirmation-dialog.network-changed.description": "The target network will change from {b, react, children{{old}}} to {b, react, children{{new}}}",
  "Web3ConfigurationScreen.confirmation-dialog.confirm": "Confirm and save",
  "Web3ConfigurationScreen.network-change-dialog.title": "Changing Network",
  "Web3ConfigurationScreen.network-change-dialog.description": "Changing to a different network will remove all saved NFT collections, are you sure to proceed?",
  "Web3ConfigurationScreen.detail-dialog.recent-added-info": "Syncing chain. It will take few minutes.",
  "Web3ConfigurationScreen.network-droplist.warning": "Switching to another network will clear your NFT Collections",
  "Web3ConfigurationScreen.detail-dialog.contract-address": "Contract Address",
  "Web3ConfigurationScreen.detail-dialog.view-on-explorer": "View on Explorer",
  "Web3ConfigurationScreen.detail-dialog.network": "Network",
  "Web3ConfigurationScreen.detail-dialog.token-type": "Token Type",
  "Web3ConfigurationScreen.detail-dialog.token-type.erc721": "ERC-721",
  "Web3ConfigurationScreen.detail-dialog.token-type.erc1155": "ERC-1155",
  "Web3ConfigurationScreen.detail-dialog.token-type.unknown": "Unknown",
  "Web3ConfigurationScreen.detail-dialog.block-height": "Block Height",
  "Web3ConfigurationScreen.detail-dialog.block-height.description": "{currentHeight} / {DocLink, react, href{{explorerUrl}} children{Latest Block Height}}",
  "Web3ConfigurationScreen.detail-dialog.total-supply": "Tokens",
  "Web3ConfigurationScreen.detail-dialog.tracked-tokens": "Tracked Tokens",
  "Web3ConfigurationScreen.detail-dialog.edit-tracked-tokens": "Edit tracked tokens",
  "Web3ConfigurationScreen.detail-dialog.remove-collection": "Remove Collection",
  "Web3ConfigurationScreen.deletion-dialog.title": "Remove collection",
  "Web3ConfigurationScreen.deletion-dialog.description": "Are you sure you want to remove {collection} from your project?",
  "Web3ConfigurationScreen.contact-us-dialog.title": "Contact us",
  "Web3ConfigurationScreen.contact-us-dialog.description": "The collection you entered cannot be added.{br, react}Please contact us if this is the contract you want to use it in your project.",
  "Web3ConfigurationScreen.contact-us-dialog.action": "Contact us",

  "SIWE.disable-first": "Your project has enabled Sign in with Ethereum. Disable it{ReactRouterLink, react, to{{href}} children{here}} beforing choosing other login methods.",

  "VerificationConfigurationScreen.otp-valid-seconds.label": "6-digit OTP valid duration (seconds)",
  "VerificationConfigurationScreen.criteria.label": "{code, react, children{isVerified}} is true when ...",
  "VerificationConfigurationScreen.criteria.tooltip": "More info in the {DocLink, react, href{https://docs.authgear.com/integrate/user-profile#userinfo-endpoint} children{UserInfo endpoint}}",
  "VerificationConfigurationScreen.criteria.any": "At least one user's email/phone is verified",
  "VerificationConfigurationScreen.criteria.all": "All user's email/phone are verified",
  "VerificationConfigurationScreen.otp-failed-attempt.label": "Number of attempts allowed for 6-digit OTP",
  "VerificationConfigurationScreen.otp-failed-attempt.enabled.offText": "Unlimited",
  "VerificationConfigurationScreen.otp-failed-attempt.description": "The code becomes invalid after a number of failed attempts, and the user must resend a new one.",
  "VerificationConfigurationScreen.verification.claims.email": "Email address verification",
  "VerificationConfigurationScreen.verification.claims.phoneNumber": "Phone number verification",
  "VerificationConfigurationScreen.verification.email.required.label": "Email verification is required to complete sign up",
  "VerificationConfigurationScreen.verification.email.allowed.label": "Allow users to verify their email address from the setting page",
  "VerificationConfigurationScreen.verification.email.verify-by.label": "Verify email by",
  "VerificationConfigurationScreen.verification.phone.required.label": "Phone verification is required to complete sign up",
  "VerificationConfigurationScreen.verification.phone.allowed.label": "Allow users to verify their phone number from the setting page",
  "VerificationConfigurationScreen.verification.phone-sms.daily-sms-limit.label": "Daily 6-digit OTP limit per phone number (leave blank to turn off limit)",
  "VerificationConfigurationScreen.verification.phoneNumber.verify-by.label": "Verify phone number by",
  "VerificationConfigurationScreen.verification.resend-cooldown.label": "Resend Cooldown Duration",
  "VerificationConfigurationScreen.verification.daily-limit.label": "Daily 6-digit OTP limit per user (leave blank to turn off limit)",

  "AnonymousUsersConfigurationScreen.title": "Anonymous Users",
  "AnonymousUsersConfigurationScreen.description": "Enable or disable anonymous users. Anonymous users do not have identity. They can be promoted to normal users.",
  "AnonymousUsersConfigurationScreen.enable.label": "Enable anonymous users?",
  "AnonymousUsersConfigurationScreen.conflict-droplist.label": "User identity conflict when promote to normal user",

  "AnonymousUsersConfigurationScreen.user-lifetime.title": "User Lifetime",
  "AnonymousUsersConfigurationScreen.user-lifetime.description": "For {b, react, children{web applications}}, a new anonymous user will be created when their previous session or token becomes invalid.",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.title": "Cookie-based applications",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.label.idle-timeout": "Idle Timeout",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.label.session-lifetime": "Session Lifetime",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.label.persistent-cookie": "Persistent Cookie",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.value.seconds": "{seconds} seconds",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.tooltip.idle-timeout": "Users will be logged out after Idle Timeout",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.tooltip.session-lifetime": "Users will be logged out after Session Lifetime regardless of their activity",
  "AnonymousUsersConfigurationScreen.user-lifetime.cookie.tooltip.persistent-cookie": "If this is disabled, user will be logged out after closing the browser",
  "AnonymousUsersConfigurationScreen.user-lifetime.token.title": "Token-based applications",
  "AnonymousUsersConfigurationScreen.user-lifetime.applications-list.label.name": "Application Name",
  "AnonymousUsersConfigurationScreen.user-lifetime.applications-list.label.refresh-token-idle-timeout": "Idle Timeout (seconds)",
  "AnonymousUsersConfigurationScreen.user-lifetime.applications-list.label.refresh-token-lifetime": "Lifetime (seconds)",
  "AnonymousUsersConfigurationScreen.user-lifetime.applications-list.tooltip.refresh-token-idle-timeout": "Users will be logged out after Idle Timeout",
  "AnonymousUsersConfigurationScreen.user-lifetime.applications-list.tooltip.refresh-token-lifetime": "Users will be logged out after Lifetime regardless of their activity",
  "AnonymousUsersConfigurationScreen.user-lifetime.go-to-applications.description": "Change the timeout settings in the {ReactRouterLink, react, to{{applicationsPath}} children{Applications}} page.",

  "AnonymousIdentityConflictBehaviour.login": "Proceed to Login",
  "AnonymousIdentityConflictBehaviour.error": "Show Error",

  "BiometricConfigurationScreen.title": "Biometric Authentication",
  "BiometricConfigurationScreen.description": "Enable or disable biometric authentication for mobile apps. Enable biometric authentication allows existing user using fingerprint or face recognition to login on iOS and Android devices.",
  "BiometricConfigurationScreen.enable.label": "Enable biometric authentication",
  "BiometricConfigurationScreen.list-enabled.label": "Show biometric logins list in setting page",

  "SingleSignOnConfigurationScreen.title": "Social / Enterprise Login",
  "SingleSignOnConfigurationScreen.edit": "Edit",
  "SingleSignOnConfigurationScreen.description": "Add social or enterprise identity providers to enable frictionless sign-in for your users. Read the {DocLink, react, href{https://docs.authgear.com/strategies/how-to-setup-sso-integrations} children{integration guide}} if you need help.",
  "SingleSignOnConfigurationScreen.widget.alias": "Alias",
  "SingleSignOnConfigurationScreen.widget.client-id": "Client ID",
  "SingleSignOnConfigurationScreen.widget.client-secret": "Client Secret",
  "SingleSignOnConfigurationScreen.widget.tenant": "Tenant",
  "SingleSignOnConfigurationScreen.widget.policy": "Policy",
  "SingleSignOnConfigurationScreen.widget.policy.placeholder": "Start with b2c_1_",
  "SingleSignOnConfigurationScreen.widget.key-id": "Key ID",
  "SingleSignOnConfigurationScreen.widget.team-id": "Team ID",
  "SingleSignOnConfigurationScreen.widget.account-id": "Account ID (原始 ID)",
  "SingleSignOnConfigurationScreen.widget.is-sandbox-account": "Is Sandbox account",
  "SingleSignOnConfigurationScreen.widget.email-required": "Require the email attribute",
  "SingleSignOnConfigurationScreen.widget.create-disabled": "Forbid the user to connect after signup",
  "SingleSignOnConfigurationScreen.widget.delete-disabled": "Forbid the user to disconnect after signup",
  "SingleSignOnConfigurationScreen.widget.discovery-document-endpoint": "Discovery Document Endpoint",
  "SingleSignOnConfigurationScreen.widget.client-secret.error": "Invalid client secret",
  "SingleSignOnConfigurationScreen.widget.add-uri": "Add URI",
  "SingleSignOnConfigurationScreen.widget.wechat-redirect-uris-label": "WeChat redirect URIs",
  "SingleSignOnConfigurationScreen.widget.wechat-redirect-uris-tooltip-message": "For mobile app only, to receive delegate call when user click on login with WeChat button.",
  "SingleSignOnConfigurationScreen.whatsapp-otp-doc.message": "Looking for WhatsApp OTP? See the {DocLink, react, href{https://docs.authgear.com/strategies/whatsapp-otp-login} children{instructions here}}.",

  "OAuthBranding.apple": "Sign in with Apple",
  "OAuthBranding.google": "Sign in with Google",
  "OAuthBranding.facebook": "Login with Facebook",
  "OAuthBranding.github": "Sign in with GitHub",
  "OAuthBranding.linkedin": "Sign in with LinkedIn",
  "OAuthBranding.azureadv2": "Sign in with Microsoft Azure AD",
  "OAuthBranding.azureadb2c": "Sign in with Microsoft Azure AD B2C",
  "OAuthBranding.adfs": "Sign in with Microsoft AD FS",
  "OAuthBranding.wechat.web": "Sign in with WeChat (Web/网站应用)",
  "OAuthBranding.wechat.mobile": "Sign in with WeChat (Mobile/移动应用)",

  "PasswordPolicyConfigurationScreen.force-change.label": "Force password change on next login if it does not meet the policy requirements",
  "PasswordPolicyConfigurationScreen.min-length.label": "Minimum password length",
  "PasswordPolicyConfigurationScreen.require-digit.label": "Requires at least 1 digit (0-9)",
  "PasswordPolicyConfigurationScreen.require-lowercase.label": "Requires at least 1 lowercase character (a-z)",
  "PasswordPolicyConfigurationScreen.require-uppercase.label": "Requires at least 1 uppercase character (A-Z)",
  "PasswordPolicyConfigurationScreen.require-alphabet.label": "Requires at least 1 alphabetic character (a-z,A-Z)",
  "PasswordPolicyConfigurationScreen.require-symbol.label": "Require at least 1 non-alphanumeric character (e.g. ~`!@#$%^&*()-_=+['{']'}'|;:''\",<.>/?)",
  "PasswordPolicyConfigurationScreen.min-guessable-level.label": "Min. password strength level",
  "PasswordPolicyConfigurationScreen.min-guessable-level.0": "N/A",
  "PasswordPolicyConfigurationScreen.min-guessable-level.1": "Extremely guessable",
  "PasswordPolicyConfigurationScreen.min-guessable-level.2": "Very guessable",
  "PasswordPolicyConfigurationScreen.min-guessable-level.3": "Fair",
  "PasswordPolicyConfigurationScreen.min-guessable-level.4": "Very unguessable",
  "PasswordPolicyConfigurationScreen.min-guessable-level.5": "Extremely unguessable",
  "PasswordPolicyConfigurationScreen.prevent-reuse.label": "Prevent Password Reuse",
  "PasswordPolicyConfigurationScreen.history-days.label": "Day(s) to keep password history",
  "PasswordPolicyConfigurationScreen.history-size.label": "No. of password history to keep",
  "PasswordPolicyConfigurationScreen.excluded-keywords.label": "Keywords to be excluded",

  "LoginMethodConfigurationScreen.password.expiry": "Password Expiry",
  "LoginMethodConfigurationScreen.password.expiry.description": "Configure actions upon password expiry.",
  "LoginMethodConfigurationScreen.password.expiry.enable-force-change.label": "Force password change on next login if it has expired",
  "LoginMethodConfigurationScreen.password.expiry.force-change-since-last-update.label": "Force change since last update (days)",

  "PasswordSettings.reset-link-valid-duration.label": "Reset link valid duration (seconds)",
  "PasswordSettings.reset-code-valid-duration.label": "Reset OTP valid duration (seconds)",
  "PasswordSettings.resetPasswordWithEmail.label": "Reset password with email by:",
  "PasswordSettings.resetPasswordWithPhone.label": "Reset password with phone by:",
  "PasswordSettings.resetPasswordWithEmail.options.link": "Link",
  "PasswordSettings.resetPasswordWithEmail.options.code": "One-time password (OTP)",
  "PasswordSettings.resetPasswordWithPhone.options.sms": "SMS",
  "PasswordSettings.resetPasswordWithPhone.options.whatsapp": "WhatsApp",
  "PasswordSettings.resetPasswordWithPhone.options.whatsappOrSMS": "WhatsApp or SMS",

  "ApplicationsConfigurationScreen.title": "Applications",
  "ApplicationsConfigurationScreen.description": "Configure applications to use this project for authentication.",
  "ApplicationsConfigurationScreen.add-client-button": "Add Application",
  "ApplicationsConfigurationScreen.client-list.name": "Name",
  "ApplicationsConfigurationScreen.client-list.client-id": "Client ID",
  "ApplicationsConfigurationScreen.client-list.application-type": "Type",

  "App2AppConfigurationScreen.title": "App2App",
  "App2AppConfigurationScreen.description": "Authenticate the user through another app which is installed in the same device without sharing cookies and login sessions.",
  "App2AppConfigurationScreen.table.description": "Enable and set up App2App login in the Application configuration.",
  "App2AppConfigurationScreen.columns.name": "Name",
  "App2AppConfigurationScreen.columns.status": "Status",
  "App2AppConfigurationScreen.columns.action": "Action",
  "App2AppConfigurationScreen.status.enabled": "Enabled",
  "App2AppConfigurationScreen.status.disabled": "Disabled",
  "App2AppConfigurationScreen.action.setup": "Setup",

  "CreateOAuthClientScreen.title": "New Application",
  "CreateOAuthClientScreen.name.label": "Name",
  "CreateOAuthClientScreen.name.description": "The name is used to identify your application. This can be changed later",
  "CreateOAuthClientScreen.application-type.label": "Application Type",
  "CreateOAuthClientScreen.application-type.description.spa": "Front-end frameworks that use access tokens to access APIs, e.g. React, Vue, Angular",
  "CreateOAuthClientScreen.application-type.description.traditional-webapp": "Websites that use cookie to authenticate requests, e.g. Express, PHP, Go",
  "CreateOAuthClientScreen.application-type.description.native": "Mobile and desktop apps, e.g. iOS, Android, Electron",
  "CreateOAuthClientScreen.application-type.description.confidential": "Allow users to login with Authgear to access any other OIDC compatible applications. e.g. WordPress, Craftcms, Magento",
  "CreateOAuthClientScreen.application-type.description.third-party-app": "Legacy. Please create a new OIDC Client Application instead.",

  "EditOAuthClientScreen.client-not-found": "Client ID {clientID} is not found",
  "EditOAuthClientScreen.quick-start-screen.title": "All set! Here are the guides to help your integration.",
  "EditOAuthClientScreen.quick-start-screen.question": "{applicationType, select, confidential{Configure your OpenID Connect compatible applications} third_party_app{Legacy. Please create a new OIDC Client Application instead.} other{What framework are you using for your {applicationType, select, spa{single page application} traditional_webapp{traditional web application} native{native app} other{application}}?}}",
  "EditOAuthClientScreen.quick-start-widget.title": "Quickstart guide",
  "EditOAuthClientScreen.quick-start-widget.question": "{applicationType, select, unspecified{Select the application type to see the guides} confidential{Configure your OpenID Connect compatible applications} third_party_app{Legacy. Please create a new OIDC Client Application instead.} other{What framework are you using?}}",
  "EditOAuthClientScreen.quick-start.open-tutorial.label": "Open tutorial",

  "EditOAuthClientScreen.quick-start.framework.react": "React",
  "EditOAuthClientScreen.quick-start.framework.vue": "Vue",
  "EditOAuthClientScreen.quick-start.framework.angular": "Angular",
  "EditOAuthClientScreen.quick-start.framework.other-js": "Other JavaScript Framework",
  "EditOAuthClientScreen.quick-start.framework.traditional-webapp": "Traditional Web Application",
  "EditOAuthClientScreen.quick-start.framework.react-native": "React Native",
  "EditOAuthClientScreen.quick-start.framework.ios": "iOS",
  "EditOAuthClientScreen.quick-start.framework.android": "Android",
  "EditOAuthClientScreen.quick-start.framework.flutter": "Flutter",
  "EditOAuthClientScreen.quick-start.framework.xamarin": "Xamarin",
  "EditOAuthClientScreen.quick-start.framework.oidc": "Setup OIDC Client",

  "EditOAuthClientScreen.delete-client.label": "Remove",
  "EditOAuthClientScreen.delete-client-dialog.title": "Delete Application",
  "EditOAuthClientScreen.delete-client-dialog.description": "Are you sure you want to delete the application?",

  "EditOAuthClientForm.basic-info.title": "Basic Info",
  "EditOAuthClientForm.name.label": "Name",
  "EditOAuthClientForm.client-id.label": "Client ID",
  "EditOAuthClientForm.client-secret.label": "Client Secret",
  "EditOAuthClientForm.endpoint.label": "Endpoint",
  "EditOAuthClientForm.application-type.label": "Application Type",
  "EditOAuthClientForm.uris.title": "URIs",
  "EditOAuthClientForm.redirect-uris.label": "Authorized Redirect URIs",
  "EditOAuthClientForm.redirect-uris.description.spa": "After users login, users will be redirected to these URIs. Call the finishAuthentication() method in your code to complete the authentication.",
  "EditOAuthClientForm.redirect-uris.description.traditional-webapp": "After users login, users will be redirected to these URIs. Call the finishAuthentication() method in your code to complete the authentication.",
  "EditOAuthClientForm.redirect-uris.description.native": "After users are authenticated, users will be redirected to these URIs. This should be a page in your application.",
  "EditOAuthClientForm.redirect-uris.description.confidential": "After users are authenticated, users will be redirected to these URIs. This should be a page in your application.",
  "EditOAuthClientForm.redirect-uris.description.third-party-app": "After users are authenticated, users will be redirected to these URIs. This should be a page in your application.",
  "EditOAuthClientForm.redirect-uris.description.unspecified": "After users are authenticated, users will be redirected to these URIs. This should be a page in your application.",
  "EditOAuthClientForm.post-logout-redirect-uris.label": "Post Logout Redirect URIs",
  "EditOAuthClientForm.post-logout-redirect-uris.description": "Users will be redirected to these paths after they have logged out. The URIs must be whitelisted here.",
  "EditOAuthClientForm.post-logout-redirect-uris.spa.description": "Post Logout Redirect URIs are not needed for the web SDK @authgear/web >= 2.0.0. In the old version SDK, users will be redirected to these paths after they have logged out. The URIs must be whitelisted here.",
  "EditOAuthClientForm.cookie-settings.title": "Cookie-based authentication",
  "EditOAuthClientForm.cookie-settings.description": "Change cookie session lifetime and timeout for {hostname} in the {ReactRouterLink, react, to{{to}} children{Cookie Session Settings}}.",
  "EditOAuthClientForm.add-uri": "Add URI",
  "EditOAuthClientForm.consent-screen.title": "Consent Screen",
  "EditOAuthClientForm.client-name.label": "Display Name",
  "EditOAuthClientForm.client-name.description": "The name of the app asking for consent",
  "EditOAuthClientForm.policy-uri.label": "Privacy Policy Link",
  "EditOAuthClientForm.policy-uri.description": "This link can lead users to your privacy policy",
  "EditOAuthClientForm.tos-uri.label": "Terms of Service Link",
  "EditOAuthClientForm.tos-uri.description": "This link can lead users to your terms of service",
  "EditOAuthClientForm.custom-ui.title": "Custom UI",
  "EditOAuthClientForm.custom-ui-uri.label": "Custom UI URI",
  "EditOAuthClientForm.custom-ui-uri.description": "Redirecting users to the custom UI for authentication.",
  "EditOAuthClientForm.refresh-token.title": "Refresh Token",
  "EditOAuthClientForm.refresh-token.label": "Refresh Token Lifetime (seconds)",
  "EditOAuthClientForm.refresh-token.description": "This is the maximum lifetime of a user session, the user will be logged out when the refresh token expires.",
  "EditOAuthClientForm.refresh-token-idle-timeout-enabled.label": "Expire after idling",
  "EditOAuthClientForm.refresh-token-idle-timeout-enabled.description": "After a period of inactivity, the refresh token will expire and the user will be logged out.",
  "EditOAuthClientForm.refresh-token-idle-timeout.label": "Idle Timeout (seconds)",
  "EditOAuthClientForm.refresh-token-idle-timeout.description": "The length of inactivity before the token expires.",
  "EditOAuthClientForm.refresh-token.help-text.idle-timeout-enabled": "A user's session will expire {refreshTokenLifetime} seconds after login or after {refreshTokenIdleTimeout} seconds of inactivity.",
  "EditOAuthClientForm.refresh-token.help-text.idle-timeout-disabled": "A user's session will expire {refreshTokenLifetime} seconds after login.",
  "EditOAuthClientForm.access-token.title": "Access Token",
  "EditOAuthClientForm.access-token.label": "Access Token Lifetime (seconds)",
  "EditOAuthClientForm.access-token.description": "How frequent the access token should be refreshed. This will affect the timeliness of the information held in the JWT token.",
  "EditOAuthClientForm.issue-jwt-access-token.label": "Issue JWT as access token",
  "EditOAuthClientForm.endpoints.title": "Endpoints",
  "EditOAuthClientForm.authorization-endpoint.label": "Authorization Endpoint",
  "EditOAuthClientForm.token-endpoint.label": "Token Endpoint",
  "EditOAuthClientForm.userinfo-endpoint.label": "Userinfo Endpoint",
  "EditOAuthClientForm.end-session-endpoint.label": "End Session Endpoint",
  "EditOAuthClientForm.openid-configuration-endpoint.label": "OpenID Configuration Endpoint",
  "EditOAuthClientForm.jwks-uri.label": "JSON Web Key (JWK) Set",
  "EditOAuthClientForm.expire-when-login-on-other-device.label": "Expire when login on another device",
  "EditOAuthClientForm.expire-when-login-on-other-device.description": "If user login on a new device, the existing token of this application on another device is expired and the user will be logged out.",
  "EditOAuthClientForm.app2app.title": "App2App",
  "EditOAuthClientForm.app2app.enable.label": "Enable App2App login for this application",
  "EditOAuthClientForm.app2app.enable.description": "Allow this application to handle App2App requests from other applications.",
  "EditOAuthClientForm.app2app.migration.label": "Migration Mode",
  "EditOAuthClientForm.app2app.migration.description": "App2App login requires a refresh token generated after App2App login enabled. Enable Migration mode will allow old refresh tokens to participate in app2app login. This mode is less secure and not recommended.",
  "EditOAuthClientForm.app2app.uris.description": "Setup universal link for iOS and app links for Android in {ReactRouterLink, react, to{#uris} children{Authorized Redirect URIs} preserveDefault{true}} to handle the App2App requests",

  "oauth-client.application-type.unspecified": "Unspecified (Legacy)",
  "oauth-client.application-type.spa": "Single Page Application",
  "oauth-client.application-type.traditional-webapp": "Traditional Web Application",
  "oauth-client.application-type.native": "Native App",
  "oauth-client.application-type.confidential": "OIDC Client Application",
  "oauth-client.application-type.third-party-app": "OIDC Client Application (Legacy)",

  "CustomDomainListScreen.title": "Custom Domains",
  "CustomDomainListScreen.desc": "You can add a custom domain so that users will login via, for example, “identity.yourdomain.com”. In this case, a session cookie of domain “yourdomain.com” is set so that your websites can share the session cookies under “*.yourdomain.com” for better customers experiences.",
  "CustomDomainListScreen.add-domain.generic-error": "Failed to add domain",
  "CustomDomainListScreen.add-domain.duplicated-error": "Domain is already in use",
  "CustomDomainListScreen.add-domain.invalid-error": "Invalid domain",
  "CustomDomainListScreen.domain-list.header.domain": "Domain",
  "CustomDomainListScreen.domain-list.header.status": "Status",
  "CustomDomainListScreen.domain-list.add-domain.placeholder": "Input New Domain",
  "CustomDomainListScreen.domain-list.status.active": "Active",
  "CustomDomainListScreen.domain-list.status.not-verified": "Need Verify",
  "CustomDomainListScreen.domain-list.status.verified": "Configured",
  "CustomDomainListScreen.delete-domain-dialog.title": "Delete Domain",
  "CustomDomainListScreen.delete-domain-dialog.message": "Are you sure you want to delete {domain}",
  "CustomDomainListScreen.delete-domain-dialog.generic-error": "Failed to delete domain",
  "CustomDomainListScreen.delete-domain-dialog.forbidden-error": "You have no permission to delete domain",
  "CustomDomainListScreen.activate-domain-dialog.title": "Activate Domain",
  "CustomDomainListScreen.activate-domain-dialog.message": "Are you sure you want to activate {domain}? Only one domain can be active.",
  "CustomDomainListScreen.activate-domain-dialog.generic-error": "Failed to activate domain",
  "CustomDomainListScreen.verify-success-message": "Verified domain successfully",
  "CustomDomainListScreen.redirectURLSection.title": "Default Redirect URLs",
  "CustomDomainListScreen.redirectURLSection.disabled.message": "Direct access to the login and logout page are available when custom domain is used",
  "CustomDomainListScreen.redirectURLSection.input.postLoginURL.label": "Post-login link",
  "CustomDomainListScreen.redirectURLSection.input.postLoginURL.description": "Users will be redirected after login if they visit the login page directly instead of through OAuth flow.",
  "CustomDomainListScreen.redirectURLSection.input.postLogoutURL.label": "Post-logout link",
  "CustomDomainListScreen.redirectURLSection.input.postLogoutURL.description": "Users will be redirected after logout if they visit the logout page directly.",

  "VerifyDomainScreen.title": "Verify Domain",
  "VerifyDomainScreen.desc-main": "Setup CNAME and add TXT record below to your DNS provider to verify your own {b, react, children{{domain}}}",
  "VerifyDomainScreen.error.domain-not-found": "Requested domain is not found",
  "VerifyDomainScreen.error.non-custom-verified-domain-not-found": "Cannot find domain which is non-custom and verified",
  "VerifyDomainScreen.list.header.record-type": "Type",
  "VerifyDomainScreen.list.header.host": "Host",
  "VerifyDomainScreen.list.header.value": "Value",
  "VerifyDomainScreen.error.duplicated-error": "Requested domain is already in use",
  "VerifyDomainScreen.error.verified-error": "Requested domain is already verified",
  "VerifyDomainScreen.error.not-found-error": "Domain not found",
  "VerifyDomainScreen.error.not-custom-error": "Requested domain is not a custom domain",
  "VerifyDomainScreen.error.verification-error": "Failed to verify domain",

  "ManageLanguageWidget.title": "Language",
  "ManageLanguageWidget.language-label": "{LANG}{IS_FALLBACK, select, true{ - Primary} other{}}",
  "ManageLanguageWidget.manage-languages": "Manage Languages",
  "ManageLanguageWidget.option-removed": "{LANG} (Removed)",
  "ManageLanguageWidget.save-to-select-new-language": "Save changes to select newly added language",

  "DesignScreen.title": "Design",
  "DesignScreen.configuration.organisation.label": "Organisation",
  "DesignScreen.configuration.organisation.name.label": "Name",
  "DesignScreen.configuration.theme.label": "Theme",
  "DesignScreen.configuration.theme.lightOnly": "Light mode only",
  "DesignScreen.configuration.theme.darkOnly": "Dark mode only",
  "DesignScreen.configuration.theme.auto": "Auto based on user preference",
  "DesignScreen.configuration.logo.description": "Add a PNG, JPG, or GIF image that is smaller than 100 KB and can be resized.",
  "DesignScreen.configuration.logo.label": "Logo",
  "DesignScreen.configuration.logo.light": "Light mode",
  "DesignScreen.configuration.logo.dark": "Dark mode",
  "DesignScreen.configuration.logo.height.label.light": "Logo height - light mode",
  "DesignScreen.configuration.logo.height.label.dark": "Logo height - dark mode",
  "DesignScreen.configuration.favicon.label": "Favicon",
  "DesignScreen.configuration.favicon.description": "Add a favicon to browser tabs to identify your brand. The size must be a multiple of 32px square PNG, JPG or GIF",
  "DesignScreen.configuration.imagePicker.upload": "Upload",
  "DesignScreen.configuration.card.label": "Card",
  "DesignScreen.configuration.card.alignment.label": "Alignment",
  "DesignScreen.configuration.background.label": "Background",
  "DesignScreen.configuration.background.description": "Upload a PNG, JPEG or GIF image smaller than 1 MB",
  "DesignScreen.configuration.background.color.label.light": "Background color - light mode",
  "DesignScreen.configuration.background.color.label.dark": "Background color - dark mode",
  "DesignScreen.configuration.background.image.label.light": "Image - light mode",
  "DesignScreen.configuration.background.image.label.dark": "Image - dark mode",
  "DesignScreen.configuration.borderRadius.label": "Border radius",
  "DesignScreen.configuration.button.label": "Buttons",
  "DesignScreen.configuration.button.primaryButton.label.light": "Primary button - light mode",
  "DesignScreen.configuration.button.primaryButton.label.dark": "Primary button - dark mode",
  "DesignScreen.configuration.button.primaryButtonLabel.label.light": "Primary button label - light mode",
  "DesignScreen.configuration.button.primaryButtonLabel.label.dark": "Primary button label - dark mode",
  "DesignScreen.configuration.button.borderRadiusStyle.label": "Border radius style",
  "DesignScreen.configuration.link.label": "Links",
  "DesignScreen.configuration.link.color.label.light": "Link color - light mode",
  "DesignScreen.configuration.link.color.label.dark": "Link color - dark mode",
  "DesignScreen.configuration.link.textDecoration.label": "Link decoration",
  "DesignScreen.configuration.link.urls.privacyPolicy.label": "Privacy Policy",
  "DesignScreen.configuration.link.urls.privacyPolicy.placeholder": "https://example.com/privacy-policy",
  "DesignScreen.configuration.link.urls.termsOfService.label": "Terms of Service",
  "DesignScreen.configuration.link.urls.termsOfService.placeholder": "https://example.com/terms-of-service",
  "DesignScreen.configuration.link.urls.customerSupport.label": "Customer Support",
  "DesignScreen.configuration.link.urls.customerSupport.placeholder": "https://example.com/customer-support",
  "DesignScreen.configuration.input.label": "Input field",
  "DesignScreen.configuration.input.border.label": "Border radius style",
  "DesignScreen.configuration.defaultClientURI.label": "Back to your app",
  "DesignScreen.configuration.defaultClientURI.description": "Add a button under the settings page to navigate the user to your website",
  "DesignScreen.configuration.defaultClientURI.enable.description": "Show “Back to your app” button in the settings page",
  "DesignScreen.configuration.authgearBranding.label": "Authgear branding",
  "DesignScreen.configuration.authgearBranding.upgradeToHide": "Upgrade to hide Authgear branding in your app.",
  "DesignScreen.configuration.authgearBranding.upgradeNow": "Upgrade now",
  "DesignScreen.configuration.authgearBranding.disableAuthgearLogo.label": "Display Authgear logo in Sign in and Sign up page",
  "DesignScreen.configuration.fallback": "Leave unset to fallback to primary language settings - {fallbackLanguage}",
  "DesignScreen.preview.pages.title.preview/login": "Log In",
  "DesignScreen.preview.pages.title.preview/signup": "Sign Up",
  "DesignScreen.preview.pages.title.preview/authflow/v2/enter_password": "Enter Password",
  "DesignScreen.preview.pages.title.preview/authflow/v2/enter_oob_otp": "Verification Code",
  "DesignScreen.preview.pages.title.preview/authflow/v2/use_passkey": "Passkey",
  "DesignScreen.preview.pages.title.preview/authflow/v2/enter_totp": "2-Step Verification",
  "DesignScreen.preview.pages.title.preview/authflow/v2/oob_otp_link": "Log in link",
  "DesignScreen.preview.pages.title.preview/authflow/v2/create_password": "Create Password",

  "UISettingsScreen.title": "Design",
  "UISettingsScreen.description": "Authgear comes with some simple styling for your login and signup page. You can customize the look and feel here.",
  "UISettingsScreen.favicon-title": "Favicon",
  "UISettingsScreen.favicon-description": "Upload a 32x32 pixel JPEG, PNG or GIF file below 100 KB",
  "UISettingsScreen.app-name-title": "Organization / Product Name",
  "UISettingsScreen.app-name-label": "It will be used as some placeholder text for your authentication screen, emails, etc. You can always change it later.",
  "UISettingsScreen.link-settings-title": "Link Settings",
  "UISettingsScreen.privacy-policy-link-label": "Privacy Policy Link",
  "UISettingsScreen.privacy-policy-link-description": "This link can lead users to your privacy policy",
  "UISettingsScreen.terms-of-service-link-label": "Terms of Service Link",
  "UISettingsScreen.terms-of-service-link-description": "This link can lead users to your terms of service",
  "UISettingsScreen.customer-support-link-label": "Customer Support Link",
  "UISettingsScreen.customer-support-link-description": "This link can lead users to your custom support",
  "UISettingsScreen.default-client-uri-label": "Back to Your App Link",
  "UISettingsScreen.default-client-uri-description": "If this link is provided, a Back to Your App link will be shown in the settings.",

  "UISettingsScreen.branding.title": "Authgear branding",
  "UISettingsScreen.branding.disable-authgear-logo.label": "Display Authgear logo in Login and Signup page",

  "LocalizationConfigurationScreen.title": "Email/SMS Templates",
  "LocalizationConfigurationScreen.description": "Change Email/SMS templates content for different languages",
  "LocalizationConfigurationScreen.template-content-title": "Update Template Contents",
  "LocalizationConfigurationScreen.forgot-password-link.title": "Reset Password by Link",
  "LocalizationConfigurationScreen.forgot-password-code.title": "Reset Password by OTP",
  "LocalizationConfigurationScreen.verification.title": "Verification",
  "LocalizationConfigurationScreen.passwordless-via-email.title": "Passwordless via Email",
  "LocalizationConfigurationScreen.passwordless-via-sms.title": "Passwordless via SMS",

  "CustomTextConfigurationScreen.title": "Custom Text",
  "CustomTextConfigurationScreen.description": "Customize the text in the AuthUI for different languages",

  "LanguagesConfigurationScreen.title": "Languages",
  "LanguagesConfigurationScreen.selectPrimaryLanguageWidget.title": "Primary language",
  "LanguagesConfigurationScreen.selectPrimaryLanguageWidget.description": "Choose the language will be displayed if the user's locale does not match any other supported languages.",
  "LanguagesConfigurationScreen.selectPrimaryLanguageWidget.dropdown.label": "Language",
  "LanguagesConfigurationScreen.supportedLanguages.title": "Supported languages",
  "LanguagesConfigurationScreen.builtInTranslation.title": "Built-in Translation",
  "LanguagesConfigurationScreen.builtInTranslation.description": "Translation for these languages are provided out of the box. You can use them directly by enabling them. To change the text, go to Custom Text and Email/SMS Template.",
  "LanguagesConfigurationScreen.customTranslation.title": "Custom Translation",
  "LanguagesConfigurationScreen.customTranslation.description": "You need to provide your own translation sheet for these languages. Enable them and customize the translation in Custom Text and Email/SMS Template.",

  "EditTemplatesWidget.email": "Email",
  "EditTemplatesWidget.email-subject": "Subject",
  "EditTemplatesWidget.html-email": "HTML Email",
  "EditTemplatesWidget.plaintext-email": "Plaintext Email",
  "EditTemplatesWidget.sms": "SMS",
  "EditTemplatesWidget.sms-body": "SMS Body",

  "EditTemplatesWidget.translationjson.title": "translation.json",
  "EditTemplatesWidget.translationjson.subtitle": "You can override the translation of the text by providing the key-value pairs in the box below.{br, react}The latest list of text can be found {ExternalLink, react, href{https://github.com/authgear/authgear-server/blob/{COMMIT}/resources/authgear/templates/en/translation.json} children{here}}. See {DocLink, react, href{https://docs.authgear.com/customize/localization} children{the docs}} for more instructions",

  "EditTemplatesWidget.custom-css.title": "Custom CSS",
  "EditTemplatesWidget.custom-css.subtitle": "Custom CSS to be included in every page",

  "EditTemplatesWidget.passwordless.setup.title": "Sign up",
  "EditTemplatesWidget.passwordless.login.title": "Login",

  "PaginationWidget.First": "Go to the first page",
  "PaginationWidget.Prev": "Go to the previous page",
  "PaginationWidget.Next": "Go to the next page",
  "PaginationWidget.Last": "Go to the last page",
  "PaginationWidget.Page": "Go to page {PAGE}",

  "PriorityList.order": "Order",

  "OrderButtons.move-up": "Move up",
  "OrderButtons.move-down": "Move down",

  "NavigationBlockerDialog.title": "Discard all changes?",
  "NavigationBlockerDialog.content": "Are you sure you want to discard all changes made and leave this page?",
  "NavigationBlockerDialog.confirm": "Discard and leave",

  "SwitchTabBlockerDialog.title": "Discard all changes?",
  "SwitchTabBlockerDialog.content": "Are you sure you want to discard all changes made and leave this tab?",

  "PasswordField.min-length": "Minimum {minLength} characters",
  "PasswordField.lowercase-required": "At least 1 lowercase character",
  "PasswordField.uppercase-required": "At least 1 uppercase character",
  "PasswordField.alphabet-required": "At least 1 alphabetic character",
  "PasswordField.digit-required": "At least 1 digit",
  "PasswordField.symbol-required": "At least 1 symbol",
  "PasswordField.minimum-guessable-level": "Your password strength must reach level {level} ({levelName})",
  "PasswordField.excluded-keywords": "No banned words",
  "PasswordField.history-size": "Cannot reuse previous {size} passwords",
  "PasswordField.history-days": "Cannot reuse password set within {days} days",
  "PasswordField.guessable-level.0": "N/A",
  "PasswordField.guessable-level.1": "Extremely guessable",
  "PasswordField.guessable-level.2": "Very guessable",
  "PasswordField.guessable-level.3": "Fair",
  "PasswordField.guessable-level.4": "Very unguessable",
  "PasswordField.guessable-level.5": "Extremely unguessable",
  "PasswordField.generate-password": "Generate",

  "PortalAdminSettings.title": "Portal Admins",
  "PortalAdminsSettings.invite": "Invite",
  "PortalAdminsSettings.delete-collaborator-dialog.generic-error": "Failed to delete admin",
  "PortalAdminsSettings.delete-collaborator-invitation-dialog.generic-error": "Failed to delete admin invitation",

  "RemovePortalAdminConfirmationDialog.title": "Remove Admin",
  "RemovePortalAdminConfirmationDialog.message": "Are you really want to remove admin {email}",

  "RemovePortalAdminInvitationConfirmationDialog.title": "Remove Admin Invitation",
  "RemovePortalAdminInvitationConfirmationDialog.message": "Are you really want to remove admin invitation to {email}",

  "PortalAdminList.column.email": "Email",
  "PortalAdminList.column.status": "Status",
  "PortalAdminList.column.action": "Action",
  "PortalAdminList.owner": "Owner",
  "PortalAdminList.remove": "Remove",
  "PortalAdminList.error.self-deletion": "You cannot remove yourself. Ask someone else to remove you.",

  "InviteAdminScreen.title": "Add Admin",
  "InviteAdminScreen.email.label": "Email",
  "InviteAdminScreen.add-user.label": "Add User",
  "InviteAdminScreen.duplicated-error": "Invitation has already been sent",

  "AcceptAdminInvitationScreen.title": "Admin Invitation",
  "AcceptAdminInvitationScreen.continue-to-authgear.label": "Continue to Authgear",
  "AcceptAdminInvitationScreen.login.label": "Login",
  "AcceptAdminInvitationScreen.login-with-another-user.label": "Login As Another User",
  "AcceptAdminInvitationScreen.create-new-account.label": "Create a new account",
  "AcceptAdminInvitationScreen.accept.label": "Accept",
  "AcceptAdminInvitationScreen.invalid-code-error": "Invalid invitation code",
  "AcceptAdminInvitationScreen.invalid-email-error": "The invitee email does not match your email. Did you log in a different user?",
  "AcceptAdminInvitationScreen.duplicated-collaborator-error": "Admin already exist",
  "AcceptAdminInvitationScreen.invalid-code.title": "Invalid Invite",
  "AcceptAdminInvitationScreen.invalid-code.description": "The invite link is invalid or expired. Please contact the admin to resent an invite.",
  "AcceptAdminInvitationScreen.not-authenticaed.title": "You're invited to join {b, react, children{{appID}}} on Authgear.",
  "AcceptAdminInvitationScreen.not-authenticated.description": "You will need to create a new Authgear account or login to your existing account.",
  "AcceptAdminInvitationScreen.not-invitee.title": "The invitee email does not match your logged in email",
  "AcceptAdminInvitationScreen.not-invitee.description": "Try logout and login as a different user?",
  "AcceptAdminInvitationScreen.is-invitee.title": "Confirm your invitation to join the tenant {appID}",
  "AcceptAdminInvitationScreen.is-invitee.description": "You will join the tenant with admin access by clicking the Accept button below.",
  "AcceptAdminInvitationScreen.accept-error.title": "Accept Invitation Error",

  "GeneralSettings.app-id.label": "App ID",

  "CookieLifetimeConfigurationScreen.title": "Cookie Lifetime",
  "CookieLifetimeConfigurationScreen.description": "Change cookie session lifetime and timeout for {b, react, children{{hostname}}}. If you use token-based authentication only (single page applications and native apps), you can simply ignore this config.",
  "CookieLifetimeConfigurationScreen.persistent-cookie.label": "Persistent Cookie",
  "CookieLifetimeConfigurationScreen.persistent-cookie.description": "The cookie persists after the browser is closed.",
  "CookieLifetimeConfigurationScreen.session-lifetime.label": "Cookie Lifetime (seconds)",
  "CookieLifetimeConfigurationScreen.session-lifetime.description": "This is the maximum lifetime of a user session in {hostname}. The user will need to log in again when the cookie expires.",
  "CookieLifetimeConfigurationScreen.invalidate-session-after-idling.label": "Expire after idling",
  "CookieLifetimeConfigurationScreen.invalidate-session-after-idling.description": "After a period of inactivity, the cookie will expire and the user will be logged out.",
  "CookieLifetimeConfigurationScreen.idle-timeout.label": "Idle Timeout (seconds)",
  "CookieLifetimeConfigurationScreen.idle-timeout.description": "The length of inactivity before the cookie expires.",

  "HookConfigurationScreen.title": "Hooks",
  "HookConfigurationScreen.description": "Authgear can deliver events your hooks when important events such as new user signup happen.",
  "HookConfigurationScreen.hook-settings": "Hook Settings",
  "HookConfigurationScreen.total-timeout.label": "Timeout (seconds)",
  "HookConfigurationScreen.timeout.label": "Per-hook timeout (seconds)",

  "HookConfigurationScreen.header.type.label": "Type",
  "HookConfigurationScreen.header.event.label": "Event",
  "HookConfigurationScreen.header.config.label": "Config",
  "HookConfigurationScreen.action.endpoint.label": "Endpoint:",
  "HookConfigurationScreen.action.script.label": "Script:",
  "HookConfigurationScreen.hook-kind.label": "Kind",
  "HookConfigurationScreen.hook-kind.webhook": "Webhook",
  "HookConfigurationScreen.hook-kind.denohook": "TypeScript",
  "HookConfigurationScreen.change-event.title": "Change Event",
  "HookConfigurationScreen.change-event.description": "Changing the event will overwrite your script. Are you sure to change?",
  "HookConfigurationScreen.change-event.label": "Change Event",
  "HookConfigurationScreen.edit-hook.label": "Edit Script",
  "HookConfigurationScreen.edit-hook.description": "Refer to the {DocLink, react, href{https://docs.authgear.com/integrate/events-hooks/denohooks} children{documentation}} to learn how to write the script. See the {DocLink, react, href{https://docs.authgear.com/integrate/events-hooks/event-list} children{Event List}} for the full list of events.",
  "HookConfigurationScreen.blocking-events": "Blocking Events",
  "HookConfigurationScreen.blocking-events.description": "Blocking Events allow your hooks to check and abort operations in different scenarios. {br, react} {DocLink, react, href{https://docs.authgear.com/integrate/events-hooks#blocking-events} children{Learn more about blocking events}}.",
  "HookConfigurationScreen.blocking-handlers.label": "Blocking Hooks",
  "HookConfigurationScreen.blocking-events.label": "Event type",
  "HookConfigurationScreen.blocking-event-type.user.pre_create": "User pre-create",
  "HookConfigurationScreen.blocking-event-type.user.profile.pre_update": "User Profile pre-update",
  "HookConfigurationScreen.blocking-event-type.user.pre_schedule_deletion": "Account deletion pre-schedule",
  "HookConfigurationScreen.blocking-event-type.user.pre_schedule_anonymization": "Account anonymization pre-schedule",
  "HookConfigurationScreen.blocking-event-type.oidc.jwt.pre_create": "JWT access token pre-create",
  "HookConfigurationScreen.non-blocking-events": "Non-blocking Events",
  "HookConfigurationScreen.non-blocking-events.description": "Non-blocking Events allow your hooks to receive notification asynchronously when an event happens in Authgear. {br, react} {br, react} All events will be delivered to the hook, you can filter event by the type in payload. {br, react} {DocLink, react, href{https://docs.authgear.com/integrate/events-hooks#non-blocking-events} children{Learn more about non-blocking events}}.",
  "HookConfigurationScreen.non-blocking-events-endpoints.label": "Non-blocking Hooks",
  "HookConfigurationScreen.signature.title": "Webhook Signature",
  "HookConfigurationScreen.signature.description": "Each webhook event request is signed with a secret key. You must validate the signature to ensure the request originates from Authgear. The signature is calculated as the hex encoded value of HMAC-SHA256 of the request body and is set as the HTTP header {code, react, children{x-authgear-body-signature}}. {br, react} {DocLink, react, href{https://docs.authgear.com/integrate/events-hooks/webhooks#verifying-signature} children{Learn more about validating the signature}}",
  "HookConfigurationScreen.signature.secret-key": "Secret Key",

  "AdminAPIConfigurationScreen.title": "Admin API",
  "AdminAPIConfigurationScreen.description": "The Admin API allows your server to manage your project and users via a GraphQL endpoint.",
  "AdminAPIConfigurationScreen.keys.title": "List of Admin API keys",
  "AdminAPIConfigurationScreen.keys.generate.label": "Generate new key pair",
  "AdminAPIConfigurationScreen.keys.generate.warning": "Max. 2 keys per project",
  "AdminAPIConfigurationScreen.keys.delete.tooltip": "At least 1 key per project",
  "AdminAPIConfigurationScreen.keys.delete-dialog.title": "Delete Admin API key",
  "AdminAPIConfigurationScreen.keys.delete-dialog.message": "Are you sure you want to delete this key? This action CANNOT be undone. Any applications using this key will no longer be able to access the Admin API.",
  "AdminAPIConfigurationScreen.keys.delete-dialog.confirm": "I understand, delete this key",
  "AdminAPIConfigurationScreen.column.key-id": "Key ID",
  "AdminAPIConfigurationScreen.column.created-at": "Created At",
  "AdminAPIConfigurationScreen.graphiql.title": "GraphiQL Explorer",
  "AdminAPIConfigurationScreen.graphiql.description": "Inspect the GraphQL schema and build queries with {ExternalLink, react, href{{graphqlEndpoint}} children{GraphiQL}}.{br, react}On your server you should always use the Admin API endpoint and the API keys.",
  "AdminAPIConfigurationScreen.graphiql.warning": "Please beware this is {strong, react, children{NOT}} a sandbox environment, changes are made to {strong, react, children{REAL DATA}}.",
  "AdminAPIConfigurationScreen.api-endpoint.title": "API Endpoint",
  "AdminAPIConfigurationScreen.api-endpoint.description": "Accessing the Admin API GraphQL endpoint requires your server to generate a valid JWT and include it as {code, react, children{Authorization}} HTTP header. Read {DocLink, react, href{https://docs.authgear.com/apis/admin-apis} children{the doc}} for more details.",

  "PasswordStrengthMeter.password-strength": "Password Strength",

  "ModifiedIndicator.message": "You have made unsaved changes",
  "ModifiedIndicator.confirm-dialog.title": "Reset Form State",
  "ModifiedIndicator.confirm-dialog.message": "Are you sure you want to reset form to initial state?",

  "FormContainer.reset-dialog.title": "Discard unsaved changes",
  "FormContainer.reset-dialog.message": "Are you sure you want to discard unsaved changes?",
  "FormContainer.reset-dialog.confirm": "Yes, discard",

  "AuthenticatorType.primary.password": "Password",
  "AuthenticatorType.primary.passkey": "Passkey",
  "AuthenticatorType.primary.oob-otp-email": "Passwordless via Email",
  "AuthenticatorType.primary.oob-otp-phone": "Passwordless via Phone",
  "AuthenticatorType.secondary.totp": "Google Authenticator/Authy (TOTP)",
  "AuthenticatorType.secondary.oob-otp-email": "OTP Code/Login Link via Email",
  "AuthenticatorType.secondary.oob-otp-phone": "OTP Code via Phone",
  "AuthenticatorType.secondary.password": "Additional Password",

  "AuthenticatorEmailOTPMode.code": "6-digit OTP",
  "AuthenticatorEmailOTPMode.login_link": "Login link",

  "AuthenticatorPhoneOTPMode.whatsapp_sms": "WhatsApp or SMS",
  "AuthenticatorPhoneOTPMode.sms": "SMS only",
  "AuthenticatorPhoneOTPMode.whatsapp": "WhatsApp only",

  "AuthenticatorHint.primary.oob-otp-phone": "Enable Phone as Login ID",

  "ShowUnhandledValidationErrorCause.title": "Validation error",
  "ShowUnhandledValidationErrorCause.kind": "Kind: {kind}",
  "ShowUnhandledValidationErrorCause.location": "Location: {location}",
  "ShowUnhandledValidationErrorCause.details": "Details: {details}",

  "ImageFilePicker.upload": "Upload",
  "ImageFilePicker.remove": "Remove",

  "ThemeConfigurationWidget.light-mode": "Light Mode",
  "ThemeConfigurationWidget.dark-mode": "Dark Mode",
  "ThemeConfigurationWidget.theme-color-title": "Theme Color",
  "ThemeConfigurationWidget.app-logo-title": "App Logo",
  "ThemeConfigurationWidget.app-logo-description": "It helps the end users to identify your app, and it is shown in all pages, emails, etc. JPEG, PNG and GIF are supported.{br, react}(Size limit: 100 KB)",
  "ThemeConfigurationWidget.sample-text": "Hello",
  "ThemeConfigurationWidget.primary-color": "Primary Color",
  "ThemeConfigurationWidget.text-color": "Text Color",
  "ThemeConfigurationWidget.background-color": "Background Color",
  "ThemeConfigurationWidget.preview-mode": "Preview Mode",
  "ThemeConfigurationWidget.app-logo-dropown-title": "Fixed Width or Fixed Height",
  "ThemeConfigurationWidget.fixed-width": "Fixed Width",
  "ThemeConfigurationWidget.fixed-height": "Fixed Height",
  "ThemeConfigurationWidget.value": "Value",
  "ThemeConfigurationWidget.left-right-padding": "Left and Right Padding",
  "ThemeConfigurationWidget.top-bottom-padding": "Top and Bottom Padding",
  "ThemeConfigurationWidget.custom": "Custom",
  "ThemeConfigurationWidget.preset.0": "Default",
  "ThemeConfigurationWidget.preset.1": "Tropical",
  "ThemeConfigurationWidget.preset.2": "Sunrise",
  "ThemeConfigurationWidget.preset.3": "Contrast",
  "ThemeConfigurationWidget.preset.4": "Summer",
  "ThemeConfigurationWidget.preset.5": "Unicorn",
  "ThemeConfigurationWidget.preset.6": "Orange",
  "ThemeConfigurationWidget.preset.7": "Luxurious",

  "ThemePreviewWidget.page-switch.login": "Log in",
  "ThemePreviewWidget.page-switch.signup": "Sign up",
  "ThemePreviewWidget.login-to-continue": "Log in to continue to Authgear",
  "ThemePreviewWidget.input-placeholder.email": "Email",
  "ThemePreviewWidget.use-phone-instead": "Use phone instead",
  "ThemePreviewWidget.next-button-label": "Next",
  "ThemePreviewWidget.separator-label": "or",
  "ThemePreviewWidget.apple-button-label": "Sign in with Apple",
  "ThemePreviewWidget.google-button-label": "Sign in with Google",
  "ThemePreviewWidget.facebook-button-label": "Login with Facebook",
  "ThemePreviewWidget.github-button-label": "Sign in with GitHub",
  "ThemePreviewWidget.linkedin-button-label": "Sign in with LinkedIn",
  "ThemePreviewWidget.azureadv2-button-label": "Sign in with Microsoft Azure AD",
  "ThemePreviewWidget.azureadb2c-button-label": "Sign in with Microsoft Azure AD B2C",
  "ThemePreviewWidget.adfs-button-label": "Sign in with Microsoft AD FS",
  "ThemePreviewWidget.wechat-button-label": "Login with WeChat",
  "ThemePreviewWidget.disclaimer": "By registering, you agree to our {a, react, className{{className}} children{Terms of Service}} and {a, react, className{{className}} children{Privacy Policy}}",

  "AuditLogScreen.title": "Audit Log",
  "AuditLogScreen.date-range.all": "Full Date Range",
  "AuditLogScreen.date-range.custom": "Custom Date Range",
  "AuditLogScreen.date-range.start-date": "Start Date",
  "AuditLogScreen.date-range.end-date": "End Date",
  "AuditLogScreen.refresh": "Refresh",
  "AuditLogScreen.last-update-at": "Refresh. Updated: {datetime}",
  "AuditLogScreen.acitity-kind.user": "Users Activities",
  "AuditLogScreen.acitity-kind.admin": "Admin API & Portal",
  "AuditLogScreen.clear-all-filters": "Clear all filters",
  "AuditLogScreen.search-by-user-id": "Search User ID",

  "AuditLogList.column.activity-type": "Activity Type",
  "AuditLogList.column.created-at": "Timestamp",
  "AuditLogList.column.user-id": "User ID",
  "AuditLogList.label.user-id": "{id} (deleted)",
  "AuditLogList.empty": "No activity in the selected date range.",

  "AuditLogEntryScreen.title": "Log Details",
  "AuditLogEntryScreen.activity-type": "Activity Type: {type}",
  "AuditLogEntryScreen.user-id": "User ID: {id}{deleted, select, true{ (deleted)} other{}}",
  "AuditLogEntryScreen.logged-at": "Logged at: {datetime}",
  "AuditLogEntryScreen.ip-address": "IP Address: {ip}",
  "AuditLogEntryScreen.user-agent": "User Agent: {userAgent}",
  "AuditLogEntryScreen.client-id": "Client ID: {clientID}",
  "AuditLogEntryScreen.raw-event-log": "Raw Event Log",

  "AuditLogActivityType.ALL": "All activity types",

  "AuditLogActivityType.AUTHENTICATION_IDENTITY_ANONYMOUS_FAILED": "Anonymous user login failed",
  "AuditLogActivityType.AUTHENTICATION_IDENTITY_BIOMETRIC_FAILED": "Biometric login failed",
  "AuditLogActivityType.AUTHENTICATION_IDENTITY_LOGIN_ID_FAILED": "Invalid login ID",
  "AuditLogActivityType.AUTHENTICATION_PRIMARY_OOB_OTP_EMAIL_FAILED": "Incorrect email passwordless code",
  "AuditLogActivityType.AUTHENTICATION_PRIMARY_OOB_OTP_SMS_FAILED": "Incorrect SMS passwordless code",
  "AuditLogActivityType.AUTHENTICATION_PRIMARY_PASSWORD_FAILED": "Incorrect password",
  "AuditLogActivityType.AUTHENTICATION_SECONDARY_OOB_OTP_EMAIL_FAILED": "Incorrect email MFA code",
  "AuditLogActivityType.AUTHENTICATION_SECONDARY_OOB_OTP_SMS_FAILED": "Incorrect SMS MFA code",
  "AuditLogActivityType.AUTHENTICATION_SECONDARY_PASSWORD_FAILED": "Incorrect MFA additional password",
  "AuditLogActivityType.AUTHENTICATION_SECONDARY_RECOVERY_CODE_FAILED": "Incorrect MFA recovery code",
  "AuditLogActivityType.AUTHENTICATION_SECONDARY_TOTP_FAILED": "Incorrect MFA TOTP code",
  "AuditLogActivityType.IDENTITY_EMAIL_ADDED": "Email added",
  "AuditLogActivityType.IDENTITY_EMAIL_REMOVED": "Email removed",
  "AuditLogActivityType.IDENTITY_EMAIL_UPDATED": "Email updated",
  "AuditLogActivityType.IDENTITY_EMAIL_VERIFIED": "Email verified",
  "AuditLogActivityType.IDENTITY_EMAIL_UNVERIFIED": "Email unverified",
  "AuditLogActivityType.IDENTITY_OAUTH_CONNECTED": "OAuth connected",
  "AuditLogActivityType.IDENTITY_OAUTH_DISCONNECTED": "OAuth disconnected",
  "AuditLogActivityType.IDENTITY_BIOMETRIC_ENABLED": "Biometric enabled",
  "AuditLogActivityType.IDENTITY_BIOMETRIC_DISABLED": "Biometric disabled",
  "AuditLogActivityType.IDENTITY_PHONE_ADDED": "Phone added",
  "AuditLogActivityType.IDENTITY_PHONE_REMOVED": "Phone removed",
  "AuditLogActivityType.IDENTITY_PHONE_UPDATED": "Phone updated",
  "AuditLogActivityType.IDENTITY_PHONE_VERIFIED": "Phone verified",
  "AuditLogActivityType.IDENTITY_PHONE_UNVERIFIED": "Phone unverified",
  "AuditLogActivityType.IDENTITY_USERNAME_ADDED": "Username added",
  "AuditLogActivityType.IDENTITY_USERNAME_REMOVED": "Username removed",
  "AuditLogActivityType.IDENTITY_USERNAME_UPDATED": "Username updated",
  "AuditLogActivityType.USER_ANONYMOUS_PROMOTED": "Anonymous user promoted",
  "AuditLogActivityType.USER_AUTHENTICATED": "Logged in",
  "AuditLogActivityType.USER_CREATED": "Signed up",
  "AuditLogActivityType.USER_PROFILE_UPDATED": "Profile updated",
  "AuditLogActivityType.USER_DISABLED": "User disabled",
  "AuditLogActivityType.USER_REENABLED": "User re-enabled",
  "AuditLogActivityType.USER_SIGNED_OUT": "Signed out",
  "AuditLogActivityType.USER_SESSION_TERMINATED": "Session Terminated",
  "AuditLogActivityType.USER_DELETED": "User deleted",
  "AuditLogActivityType.USER_DELETION_SCHEDULED": "Account deletion scheduled",
  "AuditLogActivityType.USER_DELETION_UNSCHEDULED": "Account deletion unscheduled",
  "AuditLogActivityType.USER_ANONYMIZED": "User anonymized",
  "AuditLogActivityType.USER_ANONYMIZATION_SCHEDULED": "Account anonymization scheduled",
  "AuditLogActivityType.USER_ANONYMIZATION_UNSCHEDULED": "Account deletion unscheduled",
  "AuditLogActivityType.BOT_PROTECTION_VERIFICATION_FAILED": "Bot protection verification failed",
  "AuditLogActivityType.EMAIL_SENT": "Email sent",
  "AuditLogActivityType.SMS_SENT": "SMS sent",
  "AuditLogActivityType.WHATSAPP_SENT": "Whatsapp sent",
  "AuditLogActivityType.WHATSAPP_OTP_VERIFIED": "Whatsapp OTP verified",
  "AuditLogActivityType.ADMIN_API_MUTATION_ANONYMIZE_USER_EXECUTED": "Admin API Mutation: Anonymize user",
  "AuditLogActivityType.ADMIN_API_MUTATION_CREATE_IDENTITY_EXECUTED": "Admin API Mutation: Create identity",
  "AuditLogActivityType.ADMIN_API_MUTATION_CREATE_SESSION_EXECUTED": "Admin API Mutation: Create session",
  "AuditLogActivityType.ADMIN_API_MUTATION_CREATE_USER_EXECUTED": "Admin API Mutation: Create user",
  "AuditLogActivityType.ADMIN_API_MUTATION_CREATE_AUTHENTICATOR_EXECUTED": "Admin API Mutation: Create authenticator",
  "AuditLogActivityType.ADMIN_API_MUTATION_DELETE_AUTHENTICATOR_EXECUTED": "Admin API Mutation: Delete authenticator",
  "AuditLogActivityType.ADMIN_API_MUTATION_DELETE_AUTHORIZATION_EXECUTED": "Admin API Mutation: Delete authorization",
  "AuditLogActivityType.ADMIN_API_MUTATION_DELETE_IDENTITY_EXECUTED": "Admin API Mutation: Delete identity",
  "AuditLogActivityType.ADMIN_API_MUTATION_DELETE_USER_EXECUTED": "Admin API Mutation: Delete user",
  "AuditLogActivityType.ADMIN_API_MUTATION_GENERATE_OOB_OTP_CODE_EXECUTED": "Admin API Mutation: Generate OOB OTP code",
  "AuditLogActivityType.ADMIN_API_MUTATION_RESET_PASSWORD_EXECUTED": "Admin API Mutation: Reset password",
  "AuditLogActivityType.ADMIN_API_MUTATION_REVOKE_ALL_SESSIONS_EXECUTED": "Admin API Mutation: Revoke all sessions",
  "AuditLogActivityType.ADMIN_API_MUTATION_REVOKE_SESSION_EXECUTED": "Admin API Mutation: Revoke session",
  "AuditLogActivityType.ADMIN_API_MUTATION_SCHEDULE_ACCOUNT_ANONYMIZATION_EXECUTED": "Admin API Mutation: Schedule account anonymization",
  "AuditLogActivityType.ADMIN_API_MUTATION_SCHEDULE_ACCOUNT_DELETION_EXECUTED": "Admin API Mutation: Schedule account deletion",
  "AuditLogActivityType.ADMIN_API_MUTATION_SEND_RESET_PASSWORD_MESSAGE_EXECUTED": "Admin API Mutation: Send reset password message",
  "AuditLogActivityType.ADMIN_API_MUTATION_SET_DISABLED_STATUS_EXECUTED": "Admin API Mutation: Set disabled status",
  "AuditLogActivityType.ADMIN_API_MUTATION_SET_VERIFIED_STATUS_EXECUTED": "Admin API Mutation: Set verified status",
  "AuditLogActivityType.ADMIN_API_MUTATION_UNSCHEDULE_ACCOUNT_ANONYMIZATION_EXECUTED": "Admin API Mutation: Unschedule account anonymization",
  "AuditLogActivityType.ADMIN_API_MUTATION_UNSCHEDULE_ACCOUNT_DELETION_EXECUTED": "Admin API Mutation: Unschedule account deletion",
  "AuditLogActivityType.ADMIN_API_MUTATION_UPDATE_IDENTITY_EXECUTED": "Admin API Mutation: Update identity",
  "AuditLogActivityType.ADMIN_API_MUTATION_UPDATE_USER_EXECUTED": "Admin API Mutation: Update user",
  "AuditLogActivityType.ADMIN_API_MUTATION_ADD_GROUP_TO_ROLES_EXECUTED": "Admin API Mutation: Add group to roles",
  "AuditLogActivityType.ADMIN_API_MUTATION_ADD_GROUP_TO_USERS_EXECUTED": "Admin API Mutation: Add group to users",
  "AuditLogActivityType.ADMIN_API_MUTATION_ADD_ROLE_TO_GROUPS_EXECUTED": "Admin API Mutation: Add role to groups",
  "AuditLogActivityType.ADMIN_API_MUTATION_ADD_ROLE_TO_USERS_EXECUTED": "Admin API Mutation: Add role to users",
  "AuditLogActivityType.ADMIN_API_MUTATION_ADD_USER_TO_GROUPS_EXECUTED": "Admin API Mutation: Add user to groups",
  "AuditLogActivityType.ADMIN_API_MUTATION_ADD_USER_TO_ROLES_EXECUTED": "Admin API Mutation: Add user to roles",
  "AuditLogActivityType.ADMIN_API_MUTATION_CREATE_GROUP_EXECUTED": "Admin API Mutation: Create group",
  "AuditLogActivityType.ADMIN_API_MUTATION_CREATE_ROLE_EXECUTED": "Admin API Mutation: Create role",
  "AuditLogActivityType.ADMIN_API_MUTATION_DELETE_GROUP_EXECUTED": "Admin API Mutation: Delete group",
  "AuditLogActivityType.ADMIN_API_MUTATION_DELETE_ROLE_EXECUTED": "Admin API Mutation: Delete role",
  "AuditLogActivityType.ADMIN_API_MUTATION_REMOVE_GROUP_FROM_ROLES_EXECUTED": "Admin API Mutation: Remove group from roles",
  "AuditLogActivityType.ADMIN_API_MUTATION_REMOVE_GROUP_FROM_USERS_EXECUTED": "Admin API Mutation: Remove group from users",
  "AuditLogActivityType.ADMIN_API_MUTATION_REMOVE_ROLE_FROM_GROUPS_EXECUTED": "Admin API Mutation: Remove role from groups",
  "AuditLogActivityType.ADMIN_API_MUTATION_REMOVE_ROLE_FROM_USERS_EXECUTED": "Admin API Mutation: Remove role from users",
  "AuditLogActivityType.ADMIN_API_MUTATION_REMOVE_USER_FROM_GROUPS_EXECUTED": "Admin API Mutation: Remove user from groups",
  "AuditLogActivityType.ADMIN_API_MUTATION_REMOVE_USER_FROM_ROLES_EXECUTED": "Admin API Mutation: Remove user from roles",
  "AuditLogActivityType.ADMIN_API_MUTATION_UPDATE_GROUP_EXECUTED": "Admin API Mutation: Update group",
  "AuditLogActivityType.ADMIN_API_MUTATION_UPDATE_ROLE_EXECUTED": "Admin API Mutation: Update role",
  "AuditLogActivityType.ADMIN_API_MUTATION_SET_PASSWORD_EXPIRED_EXECUTED": "Admin API Mutation: Mark password as expired",
  "AuditLogActivityType.PROJECT_APP_UPDATED": "Project: Configuration updated",
  "AuditLogActivityType.PROJECT_APP_SECRET_VIEWED": "Project: Secret viewed",
  "AuditLogActivityType.PROJECT_BILLING_CHECKOUT_CREATED": "Project: Billing checkout crreated",
  "AuditLogActivityType.PROJECT_BILLING_SUBSCRIPTION_CANCELLED": "Project: Billing subscription cancelled",
  "AuditLogActivityType.PROJECT_BILLING_SUBSCRIPTION_STATUS_UPDATED": "Project: Billing subscription status updated",
  "AuditLogActivityType.PROJECT_BILLING_SUBSCRIPTION_UPDATED": "Project: Billing subscription updated",
  "AuditLogActivityType.PROJECT_COLLABORATOR_DELETED": "Project: Collaborator deleted",
  "AuditLogActivityType.PROJECT_COLLABORATOR_INVITATION_ACCEPTED": "Project: Collaborator invitation accepted",
  "AuditLogActivityType.PROJECT_COLLABORATOR_INVITATION_CREATED": "Project: Collaborator invitation created",
  "AuditLogActivityType.PROJECT_COLLABORATOR_INVITATION_DELETED": "Project: Collaborator invitation deleted",
  "AuditLogActivityType.PROJECT_DOMAIN_CREATED": "Project: Domain created",
  "AuditLogActivityType.PROJECT_DOMAIN_DELETED": "Project: Domain deleted",
  "AuditLogActivityType.PROJECT_DOMAIN_VERIFIED": "Project: Domain verified",

  "FeatureConfig.disabled": "The grayed out features are not available for your project plan. {ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan} target{_blank}}.",
  "FeatureConfig.custom-domain.disabled": "Custom domains are not available for your project plan. {ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan}}.",
  "FeatureConfig.white-labeling.disabled": "To hide Authgear branding in your app. {ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan} target{_blank}}.",
  "FeatureConfig.oauth-clients.maximum": "You can only add {maximum, plural, one{1 application} other{# applications}} at once on your plan. To add more, {ReactRouterLink, react, to{{planPagePath}} children{upgrade your project plan} target{_blank}}.",
  "FeatureConfig.sso.maximum": "You can only add {maximum, plural, one{1 social / enterprise connection} other{# social / enterprise connections}} at once on your plan. To add more, {ReactRouterLink, react, to{{planPagePath}} children{upgrade your project plan} target{_blank}}.",
  "FeatureConfig.webhook.blocking-events.disabled": "Blocking hook is not available for your project plan. {ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan} target{_blank}}.",
  "FeatureConfig.webhook.non-blocking-events.disabled": "Non-blocking hook is not available for your project plan. {ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan} target{_blank}}.",
  "FeatureConfig.webhook.blocking-events.maximum": "You can only add {maximum, plural, one{1 blocking hook} other{# blocking hooks}} at once on your plan. To add more, {ReactRouterLink, react, to{{planPagePath}} children{upgrade your project plan} target{_blank}}.",
  "FeatureConfig.webhook.non-blocking-events.maximum": "You can only add {maximum, plural, one{1 non-blocking hook} other{# non-blocking hooks}} at once on your plan. To add more, {ReactRouterLink, react, to{{planPagePath}} children{upgrade your project plan} target{_blank}}.",
  "FeatureConfig.audit-log.retrieval-days": "You can only retrieve audit logs for the last {logRetrievalDays, plural, one{1 day} other{# days}}. To retrieve more logs, {ReactRouterLink, react, to{{planPagePath}} children{upgrade your project plan} target{_blank}}.",
  "FeatureConfig.collaborator": "You can only have {maximum, plural, one{1 admin} other{# admins}}. To invite more, {ReactRouterLink, react, to{{planPagePath}} children{upgrade your project plan} target{_blank}}",
  "FeatureConfig.web3-nft.maximum": "You can index {maximum, plural, one{1 collection} other{# collections}} max. {ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan} target{_blank}} to add more",
  "FeatureConfig.edit-template.disabled": "{ReactRouterLink, react, to{{planPagePath}} children{Upgrade your project plan} target{_blank}} to change the templates.",
  "SubscriptionCurrentPlanSummary.title.known-plan": "Current Plan: {name} {expiredAt, select, false{(${amount}/mo)} other{(Expire at: {expiredAt})}}",
  "SubscriptionCurrentPlanSummary.title.custom-plan": "Custom Plan: {name}",
  "SubscriptionCurrentPlanSummary.label.free": "Free",
  "SubscriptionCurrentPlanSummary.mau.title": "Monthly Active User",
  "SubscriptionCurrentPlanSummary.mau.approaching-limit": "Upgrade to increase limit",
  "SubscriptionCurrentPlanSummary.mau.limit-reached": "Upgrade to avoid account deactivation",
  "SubscriptionCurrentPlanSummary.mau.previous": " (Last month: {count})",
  "SubscriptionCurrentPlanSummary.mau.tooltip": "MAU usage is updated on a daily basis{br, react}Usage calculated by the end of the billing cycle would be most accurate",
  "SubscriptionCurrentPlanSummary.manage-subscription": "View invoices or change billing methods",
  "SubscriptionCurrentPlanSummary.next-billing-date": "Next billing date: {date}",
  "SubscriptionCurrentPlanSummary.whatsapp.title": "Whatsapp",
  "SubscriptionCurrentPlanSummary.whatsapp.tooltip": "{count1} Whatsapp (US, Canada) sent{br, react}{count2} Whatsapp (Other regions) sent",
  "SubscriptionCurrentPlanSummary.sms.title": "SMS",
  "SubscriptionCurrentPlanSummary.sms.tooltip": "{count1} SMS (US, Canada) sent{br, react}{count2} SMS (Other regions) sent",
  "SubscriptionCurrentPlanSummary.total-cost.title": "Total Cost (Estimated)",
  "SubscriptionCurrentPlanSummary.total-cost.tooltip": "This is an estimated cost based on your usage{br, react}Your total cost will be fully calcaluated by the end of the billing cycle",
  "SubscriptionCurrentPlanSummary.additional-mau.title": "Additional MAU",
  "SubscriptionCurrentPlanSummary.additional-mau.tooltip": "{count} Additional MAU added",

  "SubscriptionPlanCard.label.upgrade": "Upgrade",
  "SubscriptionPlanCard.label.downgrade": "Downgrade",
  "SubscriptionPlanCard.label.current": "Current",
  "SubscriptionPlanCard.label.subscribe": "Subscribe",
  "SubscriptionPlanCard.label.non-applicable": "Disabled",
  "SubscriptionPlanCard.label.reactivate": "Reactivate",
  "SubscriptionPlanCard.label.contact-us": "Contact Us",
  "SubscriptionPlanCard.upgrade.title": "Upgrade Your Plan",
  "SubscriptionPlanCard.upgrade.description": "When you change your plan, the prorated charges will be reflected in the next invoice. ${amount} (estimated) and any further additional SMS and MAU will be charged on {date}.{br, react}{br, react}Do you want to change your plan?",
  "SubscriptionPlanCard.downgrade.title": "Downgrade Your Plan",
  "SubscriptionPlanCard.downgrade.description": "{strong, react, children{Warning:}} Your apps may stop working if you downgrade to less features. To avoid this, make sure your project configuration fit the plan you are downgrading to.{br, react}{br, react}When you change your plan, the prorated charges will be reflected in the next invoice. ${amount} (estimated) and any further additional SMS and MAU will be charged on {date}.{br, react}{br, react}Do you want to change your plan?",

  "SubscriptionPlanCard.cancel.title": "Cancel Subscription",
  "SubscriptionPlanCard.cancel.confirmation": "We are sad to see you go. Are you sure to cancel the subscription? The subscription will expire at the end of the billing period. If you change your mind, you can reactivate it anytime.",
  "SubscriptionPlanCard.cancel.confirmation.customPlan": "Please send us an email for cancelling the plan.",
  "SubscriptionPlanCard.cancel.confirmation.customPlan.button": "Contact Us",
  "SubscriptionPlanCard.cancel.error": "Failed to cancel plan. Please send an email to {ExternalLink, react, children{hello@authgear.com} href{mailto:hello@authgear.com}} from the email address associated with your Authgear account and the plan you would like to cancel.",

  "SubscriptionPlanCard.reactivate.title": "Reactivate Subscription",
  "SubscriptionPlanCard.reactivate.confirmation": "Are you sure to reactivate the subscription?",
  "SubscriptionPlanCard.reactivate.error": "Failed to reactivate plan. Please send an email to {ExternalLink, react, children{hello@authgear.com} href{mailto:hello@authgear.com}} from the email address associated with your Authgear account and the plan you would like to reactivate.",

  "SubscriptionPlanCard.plan.features.title": "Everything in {previousPlan}, plus:",

  "SubscriptionPlanCard.plan.tagline.startups": "Simple projects & early-stage startups",
  "SubscriptionPlanCard.plan.mau-restriction.startups": "Up to 5,000 MAUs",
  "SubscriptionPlanCard.plan.features.line.0.startups": "1 Custom Domain",
  "SubscriptionPlanCard.plan.features.line.1.startups": "10 Webhooks",
  "SubscriptionPlanCard.plan.features.line.2.startups": "30 Days Log Retention",
  "SubscriptionPlanCard.plan.features.line.3.startups": "5 Admins",

  "SubscriptionPlanCard.plan.tagline.business": "Manage up to 10,000 MAUs",
  "SubscriptionPlanCard.plan.mau-restriction.business": "Up to 10,000 MAUs",
  "SubscriptionPlanCard.plan.features.line.0.business": "Unlimited Custom Domain",
  "SubscriptionPlanCard.plan.features.line.1.business": "No Visible Branding",
  "SubscriptionPlanCard.plan.features.line.2.business": "Unlimited Webhooks",
  "SubscriptionPlanCard.plan.features.line.3.business": "90 Days Log Retention",
  "SubscriptionPlanCard.plan.features.line.4.business": "Unlimited Admins",
  "SubscriptionPlanCard.plan.features.line.5.business": "SMS/WhatsApp Dedicated Account",

  "SubscriptionPlanCard.plan.tagline.enterprise": "Enterprise-level support and tailored solution",
  "SubscriptionPlanCard.plan.price.enterprise": "Get In Touch",
  "SubscriptionPlanCard.plan.mau-restriction.enterprise": "10,000+ MAUs",
  "SubscriptionPlanCard.plan.features.line.0.enterprise": "Volume Discounts",
  "SubscriptionPlanCard.plan.features.line.1.enterprise": "Private Deployment",
  "SubscriptionPlanCard.plan.features.line.2.enterprise": "Service Level Agreement (SLA)",
  "SubscriptionPlanCard.plan.features.line.3.enterprise": "Enterprise Support",
  "SubscriptionPlanCard.plan.features.line.4.enterprise": "Compliance Requirements",

  "SubscriptionPlanCard.sms.north-america": "USD {unitAmount} per SMS (US, Canada)",
  "SubscriptionPlanCard.sms.other-regions": "USD {unitAmount} per SMS (Other regions)",
  "SubscriptionPlanCard.whatsapp.north-america": "USD {unitAmount} per Whatsapp (US, Canada)",
  "SubscriptionPlanCard.whatsapp.other-regions": "USD {unitAmount} per Whatsapp (Other regions)",
  "SubscriptionPlanCard.mau": "USD {unitAmount}/mo per {divisor} additional MAUs",

  "SubscriptionScreen.footer.tax": "* Local VAT and taxes may apply.",
  "SubscriptionScreen.footer.usage-delay-disclaimer": "Reporting is not realtime. The MAU and SMS numbers are delayed up to 24 hours.",
  "SubscriptionScreen.footer.cancel": "Switch back to Free plan",
  "SubscriptionScreen.footer.expire": "Your subscription will expire on {date}",
  "SubscriptionScreen.footer.enterprise-plan": "Get enterprise support, advanced requirements, private deployment with our {ExternalLink, react, onClick{{onClick}} children{Enterprise Plan}}",
  "SubscriptionScreen.footer.pricing-details": "Check out more details and compare plan features on our {ExternalLink, react, href{https://www.authgear.com/pricing} children{pricing page}}",

  "SubscriptionScreen.title": "Billing",
  "SubscriptionScreen.recommended": "Recommended",

  "SubscriptionScreen.cards.title": "Upgrade Your Plan",
  "SubscriptionScreen.enterprise.title": "Upgrade to Enterprise",
  "SubscriptionScreen.enterprise.instructions": "Managing 10,000+ MAU? In need of customization services? Send an email to {ExternalLink, react, href{mailto:hello@authgear.com} children{hello@authgear.com}} from the email address associated with your Authgear account and we will take care of the rest.",
  "SubscriptionScreen.enterprise.cta": "Go Enterprise",

  "SubscriptionScreen.plan-name.free": "Free",
  "SubscriptionScreen.plan-name.free-approved": "Free",
  "SubscriptionScreen.plan-name.developers": "Developers",
  "SubscriptionScreen.plan-name.startups": "Startups",
  "SubscriptionScreen.plan-name.business": "Business",
  "SubscriptionScreen.plan-name.enterprise": "Enterprise",

  "SubscriptionScreen.processing-payment": "Processing Payment... \nThis may take a few minutes",
  "SubscriptionScreen.payment-declined.description": "Payment declined, please try another payment method.",
  "SubscriptionScreen.cancel-transaction.label": "Cancel transaction",
  "SubscriptionScreen.unknown-error.description": "Failed to process payment. Please send an email to {ExternalLink, react, children{hello@authgear.com} href{mailto:hello@authgear.com}} from the email address associated with your Authgear account.",
  "SubscriptionScreen.cancel-transaction-error.description": "Failed to cancel transaction. Please send an email to {ExternalLink, react, children{hello@authgear.com} href{mailto:hello@authgear.com}} from the email address associated with your Authgear account.",
  "SubscriptionScreen.contact-us.label": "Contact us at hello@authgear.com",

  "SMTPConfigurationScreen.title": "Custom Email Provider",
  "SMTPConfigurationScreen.description": "Optimize for email deliverability by using your own SMTP server to send Authgear Emails (such as password reset code, verification) in your own domains",
  "SMTPConfigurationScreen.enable.label": "Use my own provider",
  "SMTPConfigurationScreen.host.label": "Host",
  "SMTPConfigurationScreen.host.tooltip": "Hostname or IP address of your SMTP server",
  "SMTPConfigurationScreen.port.label": "Port",
  "SMTPConfigurationScreen.port.tooltip": "Port of your SMTP server. Typical values are 25, 465, 587. Avoid using port 25 if you can, since many providers have limitations on this port.",
  "SMTPConfigurationScreen.username.label": "Username",
  "SMTPConfigurationScreen.sendgrid.api-key.label": "API Key",
  "SMTPConfigurationScreen.sendgrid.api-key.tooltip": "Your SendGrid API Key",
  "SMTPConfigurationScreen.password.label": "Password",
  "SMTPConfigurationScreen.send-test-email.label": "Send Test Email",
  "SMTPConfigurationScreen.send-test-email-dialog.title": "Enter the recipient email address",
  "SMTPConfigurationScreen.send-test-email-dialog.description": "Test your SMTP configuration by sending an email to the given address.",
  "SMTPConfigurationScreen.provider.sendgrid": "SendGrid",
  "SMTPConfigurationScreen.provider.custom": "SMTP Provider",
  "SMTPConfigurationScreen.sendgrid.description": "Sign up for a SengGrid account and generate a SendGrid API Key. Check {DocLink, react, href{https://docs.authgear.com/customize/custom-email-provider} children{our instructions}} if you need help.",
  "SMTPConfigurationScreen.custom.description": "Set your SMTP provider manually. Check {DocLink, react, href{https://docs.authgear.com/customize/custom-email-provider} children{our instructions}} if you need help.",

  "StandardAttributesConfigurationScreen.title": "Standard Attributes",

  "CustomAttributesConfigurationScreen.title": "Custom Attributes",
  "CustomAttributesConfigurationScreen.empty-message": "No custom attributes",
  "CustomAttributesConfigurationScreen.label.add-new-attribute": "Add New Attribute",

  "CreateCustomAttributeScreen.title": "Add Custom Attribute",
  "EditCustomAttributeScreen.title": "Edit Custom Attribute",

  "EditCustomAttributeForm.label.attribute-name": "Attribute Name",
  "EditCustomAttributeForm.description.attribute-name": "Attribute name should consist of lowercase letters (a-z), digits (0-9) and underscore (_) only. It must start with lowercase letters (a-z), and NOT ended with an underscore (_).",
  "EditCustomAttributeForm.label.type": "Attribute Type",
  "EditCustomAttributeForm.label.options": "Options",
  "EditCustomAttributeForm.label.min": "min",
  "EditCustomAttributeForm.label.max": "max",
  "EditCustomAttributeForm.error.duplicated-attribute-name": "Attribute name is already in use",
  "EditCustomAttributeForm.error.not": "Attribute name is reserved for standard attribute",

  "UserProfileAttributesList.header.label.attribute-name": "Attribute Name",
  "UserProfileAttributesList.header.label.end_user": "End-user Access Right",
  "UserProfileAttributesList.header.tooltip.end_user": "Access control for the user profile attributes in the {DocLink, react, href{https://docs.authgear.com/integrate/auth-ui} children{End-user settings page}}",
  "UserProfileAttributesList.header.label.bearer": "Token Bearer Access Right",
  "UserProfileAttributesList.header.tooltip.bearer": "Access control for the user profile attributes in the {DocLink, react, href{https://docs.authgear.com/integrate/user-info} children{UserInfo endpoint}}",
  "UserProfileAttributesList.header.label.portal_ui": "Portal Admin Access Right",
  "UserProfileAttributesList.header.tooltip.portal_ui": "Access control for the user profile attributes in Admin Portal > User Management",

  "UserProfileAttributesList.dialog.title.pending-update": "Are you sure to change the {party, select, portal_ui{Portal Admin} bearer{Token Bearer} end_user{End-user} other{}} Access Right of “{fieldName}”?",
  "UserProfileAttributesList.dialog.adjustment.condition": "If you change the {strong, react, children{{party, select, portal_ui{Portal Admin} bearer{Token Bearer} end_user{End-user} other{}}}} Access Right of {strong, react, children{“{fieldName}”}} to {strong, react, children{{level}}}, then:",
  "UserProfileAttributesList.dialog.adjustment.consequence": "The Access Right of {strong, react, children{{party, select, end_user{End-user} bearer{Token Bearer} portal_ui{Portal Admin} other{}}}} will also be changed to {strong, react, children{{level}}}.",

  "AccountDeletionConfigurationScreen.title": "Account Deletion",
  "AccountDeletionConfigurationScreen.deletion-schedule.title": "Deletion Schedule",
  "AccountDeletionConfigurationScreen.grace-period.label": "Grace Period (Days)",
  "AccountDeletionConfigurationScreen.grace-period.description": "The end-user account will be disabled immediately upon request and deleted after the grace period",
  "AccountDeletionConfigurationScreen.scheduled-by-end-user.label": "Show \"Delete Account\" button in the end-user settings page",
  "AccountDeletionConfigurationScreen.apple-app-store.description": "Apps submitted to Apple App Store must offer account deletion within the app. Please refer to {ExternalLink, react, href{https://developer.apple.com/app-store/review/guidelines/#5.1.1} children{App Store Review Guidelines 5.1.1(v)}}",

  "AccountAnonymizationConfigurationScreen.title": "Account Anonymization",
  "AccountAnonymizationConfigurationScreen.anonymization-schedule.title": "Anonymization Schedule",
  "AccountAnonymizationConfigurationScreen.grace-period.label": "Grace Period (Days)",
  "AccountAnonymizationConfigurationScreen.grace-period.description": "The end-user account will be disabled immediately upon request and anonymized after the grace period",

  "standard-attribute.name": "Name",
  "standard-attribute.given_name": "Given Name",
  "standard-attribute.family_name": "Family Name",
  "standard-attribute.middle_name": "Middle Name",
  "standard-attribute.nickname": "Nickname",
  "standard-attribute.picture": "Picture",
  "standard-attribute.profile": "Profile",
  "standard-attribute.website": "Website",
  "standard-attribute.email": "Primary Email",
  "standard-attribute.phone_number": "Primary Phone",
  "standard-attribute.preferred_username": "Username",
  "standard-attribute.gender": "Gender",
  "standard-attribute.birthdate": "Birthdate",
  "standard-attribute.zoneinfo": "Timezone",
  "standard-attribute.locale": "Language",
  "standard-attribute.address": "Address",
  "standard-attribute.street_address": "Street Address",
  "standard-attribute.locality": "City",
  "standard-attribute.postal_code": "Postal Code",
  "standard-attribute.region": "State / Province / Prefecture / Region",
  "standard-attribute.country": "Country",
  "standard-attribute.updated_at": "Last updated at {datetime}",

  "standard-attribute.description.name": "Full name of the end-user",
  "standard-attribute.description.given_name": "Given name or first name of the end-user",
  "standard-attribute.description.family_name": "Family name or last name of the end-user",
  "standard-attribute.description.middle_name": "Middle Name of the end-user",
  "standard-attribute.description.nickname": "Casual name of the end-user",
  "standard-attribute.description.picture": "URL to the profile picture of the end-user",
  "standard-attribute.description.profile": "URL to the end-user''s profile page, such as social media profile",
  "standard-attribute.description.website": "URL to the end-user''s webpage or blog",
  "standard-attribute.description.email": "Primary email address of the end-user",
  "standard-attribute.description.phone_number": "Primary phone number of the end-user",
  "standard-attribute.description.preferred_username": "Username of the end-user",
  "standard-attribute.description.gender": "End-user''s gender. This value can be male, female or other custom string.",
  "standard-attribute.description.birthdate": "End-user''s birthdate in YYYY-MM-DD format.",
  "standard-attribute.description.zoneinfo": "{ExternalLink, react, children{tz database zone name} href{https://en.wikipedia.org/wiki/List_of_tz_database_time_zones}} representing the end-user''s time zone. For example, Europe/Paris or America/Los_Angeles.",
  "standard-attribute.description.locale": "End-user's locale, represented as a BCP47 language tag. The options are the supported language of the project. For example, zh-HK",
  "standard-attribute.description.address": "End-user''s address",

  "custom-attribute-type.string": "String",
  "custom-attribute-type.number": "Number",
  "custom-attribute-type.integer": "Integer",
  "custom-attribute-type.enum": "Dropdown",
  "custom-attribute-type.phone_number": "Phone Number",
  "custom-attribute-type.email": "Email Address",
  "custom-attribute-type.url": "URL",
  "custom-attribute-type.country_code": "Country Code",

  "user-profile.access-control-level.hidden": "Hidden",
  "user-profile.access-control-level.readonly": "Read-only",
  "user-profile.access-control-level.readwrite": "Editable",

  "AnalyticsScreen.title": "Analytics",
  "AnalyticsScreen.description": "The analytics portal shows your project's activities. The numbers are delayed up to 24 hours.",
  "AnalyticsScreen.start-date.label": "From",
  "AnalyticsScreen.end-date.label": "To",
  "AnalyticsScreen.clear-date-range.label": "Clear Date Range",
  "AnalyticsScreen.date-range.dialog-title": "Date Range",

  "AnalyticsActivityWidget.title": "Activity",
  "AnalyticsActivityWidget.monthly.label": "Monthly",
  "AnalyticsActivityWidget.weekly.label": "Weekly",
  "AnalyticsActivityWidget.active-user.label": "No. of active users",
  "AnalyticsActivityWidget.total-user.label": "No. of total users",

  "AnalyticsSignupConversionWidget.title": "Signup Conversion",
  "AnalyticsSignupConversionWidget.unique-signup-view.label": "Unique Signup View",
  "AnalyticsSignupConversionWidget.signup.label": "Signup",
  "AnalyticsSignupConversionWidget.chart.signup-percentage.label": "{percentage}% of the unique views have signed up",
  "AnalyticsSignupConversionWidget.no-data-available.label": "No Data Available",

  "AnalyticsSignupMethodsWidget.title": "User Signup Methods",
  "AnalyticsSignupMethodsWidget.chart.email.label": "Email",
  "AnalyticsSignupMethodsWidget.chart.phone.label": "Phone",
  "AnalyticsSignupMethodsWidget.chart.username.label": "Username",
  "AnalyticsSignupMethodsWidget.chart.google.label": "Google",
  "AnalyticsSignupMethodsWidget.chart.facebook.label": "Facebook",
  "AnalyticsSignupMethodsWidget.chart.github.label": "GitHub",
  "AnalyticsSignupMethodsWidget.chart.linkedin.label": "LinkedIn",
  "AnalyticsSignupMethodsWidget.chart.azureadv2.label": "Azure AD",
  "AnalyticsSignupMethodsWidget.chart.adfs.label": "MS AD FS",
  "AnalyticsSignupMethodsWidget.chart.azureadb2c.label": "Azure AD B2C",
  "AnalyticsSignupMethodsWidget.chart.apple.label": "Apple",
  "AnalyticsSignupMethodsWidget.chart.wechat.label": "WeChat",
  "AnalyticsSignupMethodsWidget.chart.anonymous.label": "Anonymous",
  "AnalyticsSignupMethodsWidget.no-data-available.label": "No Data Available",

  "BotProtectionConfigurationScreen.title": "Bot Protection",
  "BotProtectionConfigurationScreen.description": "Detect automation tool attacks and block with CAPTCHA challenges.",
  "BotProtectionConfigurationScreen.enable.label": "Enable Bot Protection",
  "BotProtectionConfigurationScreen.challengeProvider.title": "CAPTCHA Challenge Provider",
  "BotProtectionConfigurationScreen.provider.recaptchaV2.label": "reCAPTCHA V2",
  "BotProtectionConfigurationScreen.provider.cloudflare.label": "Cloudflare Turnstile",
  "BotProtectionConfigurationScreen.provider.recaptchaV2.description": "Register with Google and obtain the API keys needed. See Google's {ExternalLink, react, children{guide for reCAPTCHA} href{https://developers.google.com/recaptcha/docs/settings}}",
  "BotProtectionConfigurationScreen.provider.cloudflare.description": "Register with Cloudflare and obtain the API keys needed. See Cloudflare's {ExternalLink, react, children{guide for Turnstile} href{https://developers.cloudflare.com/turnstile/get-started/#get-a-sitekey-and-secret-key}}",
  "BotProtectionConfigurationScreen.provider.recaptchav2.siteKey.label": "Site Key",
  "BotProtectionConfigurationScreen.provider.recaptchav2.secretKey.label": "Secret Key",
  "BotProtectionConfigurationScreen.provider.cloudflare.siteKey.label": "Site Key",
  "BotProtectionConfigurationScreen.provider.cloudflare.secretKey.label": "Secret Key",
  "BotProtectionConfigurationScreen.requirements.title": "When to Require CAPTCHA",
  "BotProtectionConfigurationScreen.requirements.flows.allSignupLogin": "All signup & login flows",
  "BotProtectionConfigurationScreen.requirements.flows.specificAuthenticator": "When specific authenticator is used",
  "BotProtectionConfigurationScreen.requirements.flows.config.riskMode.never": "Never",
  "BotProtectionConfigurationScreen.requirements.flows.config.riskMode.always": "Always",
  "BotProtectionConfigurationScreen.requirements.flows.config.allSignupLogin.label": "All signup & login flows",
  "BotProtectionConfigurationScreen.requirements.flows.config.password.label": "- with Password",
  "BotProtectionConfigurationScreen.requirements.flows.config.passwordlessSMS.label": "- with Passwordless via SMS",
  "BotProtectionConfigurationScreen.requirements.flows.config.passwordlessEmail.label": "- with Passwordless via Email",
  "BotProtectionConfigurationScreen.requirements.resetPassword.subtitle": "Reset Password",
  "BotProtectionConfigurationScreen.requirements.resetPassword.config.resetPassword.label": "Reset Password",
  "BotProtectionConfigurationScreen.requirements.flows.type.dependsOnAuthenticator": "Depends on authenticator",

  "IntegrationsConfigurationScreen.title": "Integrations",
  "IntegrationsConfigurationScreen.add-on": "Add-on",
  "IntegrationsConfigurationScreen.action": "Action",
  "IntegrationsConfigurationScreen.add-on.gtm.name": "Google Tag Manager",
  "IntegrationsConfigurationScreen.add-on.gtm.description": "Connect your container to Authgear for conversion tracking, site analytics, retargeting, and more.",
  "IntegrationsConfigurationScreen.status.connected": "Connected",

  "GoogleTagManagerConfigurationScreen.title": "Google Tag Manager",
  "GoogleTagManagerConfigurationScreen.description": "Connect your container to Authgear for conversion tracking, site analytics, retargeting, and more. See {DocLink, react, href{https://docs.authgear.com/how-to-guide/integration/user-analytics-by-google-tag-manager} children{integration guide}} if you need help.",
  "GoogleTagManagerConfigurationScreen.toggle.label": "Google Tag Manager Integration",
  "GoogleTagManagerConfigurationScreen.container-id.label": "Tag Manager Container ID",
  "GoogleTagManagerConfigurationScreen.container-id.placeholder": "e.g. GTM-123456",

  "WizardContentLayout.skip.label": "Skip and I’ll set up my project manually",

  "CreateProjectScreen.title": "{apps, plural, =0{Create your first project} other{Create a new project}}",
  "CreateProjectScreen.create-project.label": "Create Project",
  "CreateProjectScreen.app-id.label": "What is the name of your project?",
  "CreateProjectScreen.app-id.description": "This is your Authgear endpoint for your applications. Once chosen, the project cannot be renamed.",
  "CreateProjectScreen.phone-number.label": "Would you mind to share us your phone number?",
  "CreateProjectScreen.error.duplicated-app-id": "Project ID already exist",
  "CreateProjectScreen.error.reserved-app-id": "Project ID is reserved",
  "CreateProjectScreen.error.invalid-app-id": "Invalid Project ID. Project ID should start and end with lowercase alphanumeric characters or numbers, and it should contain only lowercase alphanumeric characters, numbers or '-'",

  "ProjectWizardScreen.step.title": "Setting up {appID} ({currentStep}/{totalStep})",
  "ProjectWizardScreen.step1.question": "How should the users sign in to your application?",
  "ProjectWizardScreen.step1.option.email-password": "Email and Password",
  "ProjectWizardScreen.step1.option.oob-otp-email": "Passwordless via Email",
  "ProjectWizardScreen.step1.tooltip.oob-otp-email": "User will sign in by receiving one-time password (OTP) via email",
  "ProjectWizardScreen.step1.tooltip.oob-otp-sms": "User will sign in by receiving one-time password (OTP) via SMS",
  "ProjectWizardScreen.step1.option.phone-number-password": "Phone number and Password",
  "ProjectWizardScreen.step1.option.oob-otp-sms": "Passwordless via Phone number",
  "ProjectWizardScreen.step2.question": "Can users sign in with passkeys?",
  "ProjectWizardScreen.step2.description": "Passkeys are supported in newer platforms such as iOS 16. It is designed to replace passwords and other passwordless login methods. Users can still use traditional login methods on incompatible devices.",
  "ProjectWizardScreen.step2.option.true": "Yes, enable passkeys in my app",
  "ProjectWizardScreen.step2.option.false": "No, users cannot use passkeys",

  "ProjectWizardScreen.step3.q1": "Enable two-factor authentication (2FA) for your app?",
  "ProjectWizardScreen.step3.q1.option.true": "Use the recommended settings",
  "ProjectWizardScreen.step3.q1.option.false": "Custom settings",
  "ProjectWizardScreen.step3.recommended-settings.title": "Recommended settings",
  "ProjectWizardScreen.step3.recommended-settings.description": "Users can enable 2FA optionally, authenticate by TOTP Devices/Apps such as Google Authenticator & Authy.",
  "ProjectWizardScreen.step3.custom-settings.title": "Custom settings",
  "ProjectWizardScreen.step3.q2": "Require 2FA to sign in?",
  "ProjectWizardScreen.step3.q2.option.if_exists": "Users can enable 2FA optionally",
  "ProjectWizardScreen.step3.q2.option.required": "Always require 2FA for all users to sign in",
  "ProjectWizardScreen.step3.q2.option.disabled": "Disable 2FA, users will never use 2FA to sign in",
  "ProjectWizardScreen.step3.q3": "How should a user perform 2FA?",
  "ProjectWizardScreen.step3.q3.option.totp": "TOTP Devices/Apps {Text, react, className{{className}} children{ e.g. Google Authenticator, Authy}}",
  "ProjectWizardScreen.step3.q3.option.oob_otp_email": "Receive One-Time-Password (OTP) via Email",
  "ProjectWizardScreen.step3.q3.option.password": "Additional Password",

  "OnboardingSurveyScreen.step1.title": "Welcome to Authgear!\nWhat is your role?",
  "OnboardingSurveyScreen.step1.subtitle": "This helps us personalize your experience.",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.Dev": "Developer",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.IT": "IT",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.PM": "Product Manager / Project Manager",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.PD": "Product Designer",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.Market": "Marketing",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.Owner": "Business Owner",
  "OnboardingSurveyScreen.step1.roleChoiceGroup.Other": "Other",
  "OnboardingSurveyScreen.step2.title": "Who will be using Authgear?",
  "OnboardingSurveyScreen.step2.subtitle": "Are you creating this account for a company or personal project?",
  "OnboardingSurveyScreen.step2.teamOrPersonalChoiceGroup.team": "My Team/Company",
  "OnboardingSurveyScreen.step2.teamOrPersonalChoiceGroup.personal": "Just me",
  "OnboardingSurveyScreen.step3-team.title": "Tell us about your company",
  "OnboardingSurveyScreen.step3-team.subtitle": "This helps us recommend the right solution to you.",
  "OnboardingSurveyScreen.step3-team.companyName.label": "Company Name",
  "OnboardingSurveyScreen.step3-team.companySizeChoiceGroup.label": "Company Size",
  "OnboardingSurveyScreen.step3-team.companySizeChoiceGroup.1-49": "1-49",
  "OnboardingSurveyScreen.step3-team.companySizeChoiceGroup.50-99": "50-99",
  "OnboardingSurveyScreen.step3-team.companySizeChoiceGroup.100-499": "100-499",
  "OnboardingSurveyScreen.step3-team.companySizeChoiceGroup.500-1999": "500-1999",
  "OnboardingSurveyScreen.step3-team.companySizeChoiceGroup.2000+": "2000+",
  "OnboardingSurveyScreen.step3-team.phone.label": "Phone (Optional)",
  "OnboardingSurveyScreen.step3-personal.title": "Tell us about your project",
  "OnboardingSurveyScreen.step3-personal.subtitle": "This helps us recommend the right solution to you.",
  "OnboardingSurveyScreen.step3-personal.projectWebsite.label": "Project Website (Optional)",
  "OnboardingSurveyScreen.step3-personal.phone.label": "Phone (Optional)",
  "OnboardingSurveyScreen.step4.title": "What brings you to Authgear?",
  "OnboardingSurveyScreen.step4.subtitle": "This helps us recommend the right solution for you.",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Auth.title": "Authentication",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Auth.subtitle": "I'm building a new software product and looking for auth.",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.SSO.title": "Enterprise SSO",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.SSO.subtitle": "I'm looking for a SSO solution for multiple apps in my company.",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Security.title": "Security",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Security.subtitle": "I want to enhance security by realtime security monitoring, brute-force attack prevention.",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Portal.title": "Management Portal",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Portal.subtitle": "I'm looking for an identity and user management solution for frontline staffs of my company.",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Other.title": "A different reason",
  "OnboardingSurveyScreen.step4.reasonChoiceGroup.Other.subtitle": "Describe your reason",
  "OnboardingSurveyScreen.step4.otherReason.label": "Describe your reason (Optional)",
  "OnboardingSurveyScreen.step4.finish": "Finish Survey",

  "GetStartedScreen.title": "Getting Started 🎉",
  "GetStartedScreen.description": "Here are a few steps to get you set up for success.",
  "GetStartedScreen.counter": "{remaining, plural, =0{Done! You are ready to go.} other{# remaining}}",
  "GetStartedScreen.help-text": " Post on our {ExternalLink, react, href{https://github.com/authgear/authgear-server/discussions} children{Community forum} onClick{{onClickForum}}} or {ExternalLink, react, href{https://www.authgear.com/talk-with-us?utm_source=portal&utm_medium=link&utm_campaign=getting_started} children{contact us} onClick{{onClickContactUs}}} to get help and support",
  "GetStartedScreen.dismiss-button.label": "I’m done with the setup. Don’t show this page again.",
  "GetStartedScreen.card.title.authui": "Sign up your first user",
  "GetStartedScreen.card.description.authui": "The authentication experience is ready! Login and account settings page are already built for you. Sign up your first user and try the UI.",
  "GetStartedScreen.card.action-label.authui": "Try it now",
  "GetStartedScreen.card.title.customize_ui": "Customize the signup & login page",
  "GetStartedScreen.card.description.customize_ui": "Change the theme and colors of the pre-built UI to fit your branding.",
  "GetStartedScreen.card.action-label.customize_ui": "Customize",
  "GetStartedScreen.card.title.create_application": "Create application",
  "GetStartedScreen.card.description.create_application": "Start integrating Authgear with your apps. Learn more about {DocLink, react, href{https://docs.authgear.com/get-started/authentication-approach} children{How Authgear works}}",
  "GetStartedScreen.card.action-label.create_application": "Create application",
  "GetStartedScreen.card.title.sso": "Add a social or enterprise login provider",
  "GetStartedScreen.card.description.sso": "Sign in to your apps with connections like Facebook, Google, Active Directory and more. {DocLink, react, href{https://docs.authgear.com/strategies/how-to-setup-sso-integrations} children{See setup instructions}}",
  "GetStartedScreen.card.action-label.sso": "Add login provider",
  "GetStartedScreen.card.title.invite": "Invite your team members",
  "GetStartedScreen.card.description.invite": "Add your team members and admin to help with your integration.",
  "GetStartedScreen.card.action-label.invite": "Invite members",
  "GetStartedScreen.card.skip-button.label": "Skip this",

  "NetworkId.ethereum-mainnet": "Ethereum Mainnet",
  "NetworkId.ethereum-goerli": "Ethereum Goerli",
  "NetworkId.polygon-mainnet": "Polygon Mainnet",
  "NetworkId.polygon-mumbai": "Polygon Mumbai",

  "NftCollection.item.identifier": "{name} ({network}: {address})",

  "MFAConfigurationScreen.title": "2-Factor Authentication",
  "MFAConfigurationScreen.description": "Enhance your users' security during login to prevent unauthorized access.",
  "MFAConfigurationScreen.policy.title": "Policy",
  "MFAConfigurationScreen.policy.mode.title": "2FA Requirements",
  "MFAConfigurationScreen.policy.mode.disabled": "Disabled, 2FA is not required to sign in, even if the user has set it up before",
  "MFAConfigurationScreen.policy.mode.if_exists": "Optional, 2FA is required to sign in if users set it up",
  "MFAConfigurationScreen.policy.mode.required": "Mandatory, 2FA is required for all users to sign in, and would be asked to set it up",
  "MFAConfigurationScreen.policy.device-token.title": "Show \"Do not ask again on this device\" for 2FA",
  "MFAConfigurationScreen.policy.enable-global-grace-period.title": "Enable global grace period",
  "MFAConfigurationScreen.policy.enable-global-grace-period.description": "Users without 2FA will be forced to set up 2FA on their next login.",
  "MFAConfigurationScreen.authenticator.title": "Available 2-Factor",
  "MFAConfigurationScreen.authenticator.description": "How can a user perform 2FA?",
  "MFAConfigurationScreen.recovery-code.title": "Recovery Code",
  "MFAConfigurationScreen.recovery-code.description": "In the case of losing access to the 2-factor authenticator, the end-user can recover their account using the recovery codes.",
  "MFAConfigurationScreen.recovery-code.toggle.title": "Enable Recovery Code",
  "MFAConfigurationScreen.recovery-code.input.title": "Number of Recovery Codes",
  "MFAConfigurationScreen.recovery-code.list.toggle.title": "Allow user to retrieve or regenerate recovery codes again",
  "MFAConfigurationScreen.unreasonable.password": "Your users sign in with password. Using \"Additional Password\" as 2-factor is not recommended.",
  "MFAConfigurationScreen.unreasonable.oob_otp_email": "Your users sign in with Email + Passwordless. Using \"OTP Code via Email\" as 2-factor is not recommended.",
  "MFAConfigurationScreen.unreasonable.oob_otp_sms": "Your users sign in with Phone Number + Passwordless. Using \"OTP Code via Phone\" as 2-factor is not recommended.",

  "LoginMethodConfigurationScreen.title": "Login Methods",

  "LoginMethodConfigurationScreen.login-method.title.passwordless-email": "Email + Passwordless",
  "LoginMethodConfigurationScreen.login-method.description.passwordless-email": "Sign in with Email OTP",
  "LoginMethodConfigurationScreen.login-method.title.passwordless-phone": "Mobile + Passwordless",
  "LoginMethodConfigurationScreen.login-method.description.passwordless-phone": "Sign in with SMS / Whatsapp OTP",
  "LoginMethodConfigurationScreen.login-method.title.passwordless-phone-email": "Mobile / Email + Passwordless",
  "LoginMethodConfigurationScreen.login-method.description.passwordless-phone-email": "Sign in with SMS / Whatsapp / Email OTP",
  "LoginMethodConfigurationScreen.login-method.title.password-email": "Email + Password",
  "LoginMethodConfigurationScreen.login-method.description.password-email": "Sign in with email and password",

  "LoginMethodConfigurationScreen.login-method.title.password-phone": "Mobile + Password",
  "LoginMethodConfigurationScreen.login-method.description.password-phone": "Sign in with mobile number and password",
  "LoginMethodConfigurationScreen.login-method.title.password-username": "Username + Password",
  "LoginMethodConfigurationScreen.login-method.description.password-username": "Sign in with username and password",
  "LoginMethodConfigurationScreen.login-method.title.password-phone-email": "Mobile / Email + Password",
  "LoginMethodConfigurationScreen.login-method.description.password-phone-email": "Sign in with mobile number or email and password",
  "LoginMethodConfigurationScreen.login-method.title.oauth": "Social / Enterprise identity providers only",
  "LoginMethodConfigurationScreen.login-method.description.oauth": "Sign in with Google, Facebook, Azure AD B2C, etc",
  "LoginMethodConfigurationScreen.login-method.title.custom": "Custom login flow",
  "LoginMethodConfigurationScreen.oauth": "Set up Social / Enterprise identity providers >",
  "LoginMethodConfigurationScreen.custom-login-methods.title": "Custom Login Methods",
  "LoginMethodConfigurationScreen.custom-login-methods.login-id.title": "Login ID",
  "LoginMethodConfigurationScreen.custom-login-methods.login-id.description": "User can sign in by Login ID. The order will affect when both options are available, which one will show up first.",
  "LoginMethodConfigurationScreen.custom-login-methods.authenticator.title": "Authenticator",
  "LoginMethodConfigurationScreen.custom-login-methods.authenticator.description": "Configure how your users can authenticate themselves. The order will affect when both options are available, which one will show up first.",
  "LoginMethodConfigurationScreen.email.title": "Email Address Settings",
  "LoginMethodConfigurationScreen.email.description": "Set the rules of email address format.",
  "LoginMethodConfigurationScreen.phone.title": "Phone Number Settings",
  "LoginMethodConfigurationScreen.phone.description": "Set the rules of phone number format.",
  "LoginMethodConfigurationScreen.username.title": "Username Settings",
  "LoginMethodConfigurationScreen.username.description": "Set the rules of username format.",
  "LoginMethodConfigurationScreen.verificationAndPasswordless.title": "Verification and Passwordless Settings",
  "LoginMethodConfigurationScreen.verificationAndPasswordless.description": "Configure how a user should be verified and using OTP to login",
  "LoginMethodConfigurationScreen.verification.title": "Verification Settings",
  "LoginMethodConfigurationScreen.verification.description": "Configure how a user should be verified",
  "LoginMethodConfigurationScreen.verification.email": "Email",
  "LoginMethodConfigurationScreen.verification.phone": "Phone",
  "LoginMethodConfigurationScreen.password.title": "Passwords",
  "LoginMethodConfigurationScreen.password.resetPassword.title": "Reset Password",
  "LoginMethodConfigurationScreen.password.description": "Configure the password policy for Password and Additional Password in MFA.",
  "LoginMethodConfigurationScreen.password.requirements": "Password Requirements",
  "LoginMethodConfigurationScreen.password.requirements.advanced": "Advanced Password Requirements",
  "LoginMethodConfigurationScreen.lockout.title": "Account Lockout Policy",
  "LoginMethodConfigurationScreen.lockout.threshold.title": "Lockout threshold",
  "LoginMethodConfigurationScreen.lockout.threshold.description": "Configure the number of failed login attempts your users can make within a time limit before their accounts are locked.",
  "LoginMethodConfigurationScreen.lockout.threshold.failedAttempts.title": "Failed Login Attempts",
  "LoginMethodConfigurationScreen.lockout.threshold.failedAttempts.description": "This is the maximum failed attempts to login before the account is locked.",
  "LoginMethodConfigurationScreen.lockout.threshold.resetAfter.title": "Reset failed attempts after (minutes)",
  "LoginMethodConfigurationScreen.lockout.threshold.resetAfter.description": "The interval to reset failed attempts after the last failure.",
  "LoginMethodConfigurationScreen.lockout.threshold.overall.description": "A user account will be locked after {attempts} failed login attempts. The failure attempts will be reset after {resetIntervalMins, plural, one{# minute} other{# minutes}} of the last failure. {resetIntervalHoursDisplayed, select, false {} other {(i.e. {resetIntervalHours, plural, one{# hour} other{# hours}})}}",
  "LoginMethodConfigurationScreen.lockout.duration.title": "Lockout duration",
  "LoginMethodConfigurationScreen.lockout.duration.description": "Configure the number of minutes an account will remain locked when it has exceeded the lockout threshold.",
  "LoginMethodConfigurationScreen.lockout.duration.duration.title": "Lockout Duration (minutes)",
  "LoginMethodConfigurationScreen.lockout.duration.duration.description": "This determines how long the account will remain locked.",
  "LoginMethodConfigurationScreen.lockout.duration.backoff.title": "Backoff Factor",
  "LoginMethodConfigurationScreen.lockout.duration.backoff.description": "This value will increase the lockout duration exponentially.",
  "LoginMethodConfigurationScreen.lockout.duration.max.title": "Maximum Lockout Duration (minutes)",
  "LoginMethodConfigurationScreen.lockout.duration.max.description": "This will allow you to put a cap on the lockout duration.",
  "LoginMethodConfigurationScreen.lockout.duration.overall.description.noBackoff": "If a user account has exceeded the lockout threshold, it will be locked for {durationMins, plural, one{# minute} other{# minutes}}. For every subsequent failed attempt, the user must wait for another {durationMins, plural, one{# minute} other{# minutes}} until a correct password is entered.",
  "LoginMethodConfigurationScreen.lockout.duration.overall.description.withBackoff": "If a user account has exceeded the lockout threshold, it will be locked for {durationMins, plural, one{# minute} other{# minutes}}. For every subsequent failed attempt, lockout duration will increase by {backoffFactor} times (e.g. {durationMins, plural, one{# min} other{# mins}}, {durationMinsSecond, plural, one{# min} other{# mins}}, {durationMinsThird, plural, one{# min} other{# mins}}...) until it reaches the maximum of {maxDurationMins, plural, one{# minute} other{# minutes}} {maxDurationHoursDisplayed, select, false{} other {(i.e. {maxDurationHours, plural, one{# hour} other{# hours}})}}.",
  "LoginMethodConfigurationScreen.lockout.type.title": "Lockout type",
  "LoginMethodConfigurationScreen.lockout.type.description": "This determines whether the account lockout is only applied when the user attempts to login with the same IP address, or when the user attempts to login on any devices.",
  "LoginMethodConfigurationScreen.lockout.type.perUser": "Per user on any devices",
  "LoginMethodConfigurationScreen.lockout.type.perUserPerIP": "Per user on the same IP address",
  "LoginMethodConfigurationScreen.lockout.authenticator.title": "Apply policy to selected authenticators",
  "LoginMethodConfigurationScreen.lockout.authenticator.description": "Select the types of authenticator where failed login attempts will be counted.",
  "LoginMethodConfigurationScreen.lockout.authenticator.password": "Password",
  "LoginMethodConfigurationScreen.lockout.authenticator.passwordless": "Passwordless via Phone/Email",
  "LoginMethodConfigurationScreen.lockout.authenticator.totp": "Authenticator App (TOTP)",
  "LoginMethodConfigurationScreen.lockout.authenticator.recoveryCode": "Recovery code",
  "LoginMethodConfigurationScreen.lockout.errors.maxDurationMustBeGreaterThanMinDuration": "Maximum Lockout Duration should be greater than or equal to Lockout Duration",
  "LoginMethodConfigurationScreen.lockout.errors.mustEnableForAtLeastOneAuthenticator": "Please select at least one authenticator",
  "LoginMethodConfigurationScreen.lockout.enable": "Enable account lockout policy?",

  "LoginMethodConfigurationScreen.pivot.custom.title": "Custom Login Methods",
  "LoginMethodConfigurationScreen.pivot.email.title": "Email",
  "LoginMethodConfigurationScreen.pivot.phone.title": "Phone",
  "LoginMethodConfigurationScreen.pivot.username.title": "Username",
  "LoginMethodConfigurationScreen.pivot.verification.title": "Verification",
  "LoginMethodConfigurationScreen.pivot.verificationAndPasswordless.title": "Verification and Passwordless",
  "LoginMethodConfigurationScreen.pivot.password.title": "Passwords",
  "LoginMethodConfigurationScreen.pivot.lockout.title": "Account Lockout Policy",
  "LoginMethodConfigurationScreen.with-passkey": "with Passkey",
  "LoginMethodConfigurationScreen.chooser.title": "Select Login Methods",
  "LoginMethodConfigurationScreen.chooser.subtitle": "Authentication",
  "LoginMethodConfigurationScreen.first-level.email.title": "Email",
  "LoginMethodConfigurationScreen.first-level.email.description": "Sign in with email address",
  "LoginMethodConfigurationScreen.first-level.phone.title": "Mobile",
  "LoginMethodConfigurationScreen.first-level.phone.description": "Sign in with mobile number",
  "LoginMethodConfigurationScreen.first-level.phone-email.title": "Mobile / Email",
  "LoginMethodConfigurationScreen.first-level.phone-email.description": "Sign in with mobile number or email address",
  "LoginMethodConfigurationScreen.first-level.username.title": "Username",
  "LoginMethodConfigurationScreen.first-level.username.description": "Sign in with username",
  "LoginMethodConfigurationScreen.first-level.oauth.title": "Social / Enterprise only",
  "LoginMethodConfigurationScreen.first-level.oauth.description": "Sign in with identity providers only",
  "LoginMethodConfigurationScreen.first-level.custom.title": "Custom",
  "LoginMethodConfigurationScreen.first-level.custom.description": "Configure your custom login methods",
  "LoginMethodConfigurationScreen.second-level.passwordless.title": "Passwordless",
  "LoginMethodConfigurationScreen.second-level.passwordless.description": "One-time password (OTP)",
  "LoginMethodConfigurationScreen.second-level.password.title": "Enter password",
  "LoginMethodConfigurationScreen.second-level.password.description": "Enter password to authenticate",
  "LoginMethodConfigurationScreen.passkey.title": "Enable passkey support for compatible devices. {DocLink, react, href{https://docs.authgear.com/strategies/passkeys} children{Learn more about passkey}}",
  "LoginMethodConfigurationScreen.combineLoginSignup.title": "Automatically signup a new user if a login ID is not found during login",

  "EditConfigurationScreen.title": "Edit Project Configurations",

  "LoginIDKeyType.email": "Email address",
  "LoginIDKeyType.phone": "Phone number",
  "LoginIDKeyType.username": "Username",

  "PrimaryAuthenticatorType.password": "Password",
  "PrimaryAuthenticatorType.oob_otp_email": "Passwordless via Email",
  "PrimaryAuthenticatorType.oob_otp_sms": "Passwordless via SMS",

  "FormConfirmOverridingDialog.title": "The project was edited in another browser tab or by another user.",
  "FormConfirmOverridingDialog.subtext": "You can reload this page to use the latest version. Do you want to override the changes?",
  "FormConfirmOverridingDialog.button.cancel": "Cancel",
  "FormConfirmOverridingDialog.button.confirm": "Override Changes",

  "SearchableDropdown.empty": "No results found",

  "UnauthenticatedDialog.title": "Session expired",
  "UnauthenticatedDialog.description": "Your session is expired due to inactivity. Please login again.",
  "UnauthenticatedDialog.button": "Login again",

  "Locales.af": "Afrikaans",
  "Locales.am": "Amharic",
  "Locales.ar": "Arabic",
  "Locales.hy": "Armenian",
  "Locales.az": "Azerbaijani",
  "Locales.eu": "Basque",
  "Locales.be": "Belarusian",
  "Locales.bn": "Bengali",
  "Locales.bg": "Bulgarian",
  "Locales.my": "Burmese",
  "Locales.ca": "Catalan",
  "Locales.zh-HK": "Chinese (Hong Kong)",
  "Locales.zh-CN": "Chinese (Simplified)",
  "Locales.zh-TW": "Chinese (Traditional)",
  "Locales.hr": "Croatian",
  "Locales.cs": "Czech",
  "Locales.da": "Danish",
  "Locales.nl": "Dutch",
  "Locales.en": "English",
  "Locales.et": "Estonian",
  "Locales.fil": "Filipino",
  "Locales.fi": "Finnish",
  "Locales.fr": "French",
  "Locales.gl": "Galician",
  "Locales.ka": "Georgian",
  "Locales.de": "German",
  "Locales.el": "Greek",
  "Locales.hi": "Hindi",
  "Locales.hu": "Hungarian",
  "Locales.is": "Icelandic",
  "Locales.id": "Indonesian",
  "Locales.it": "Italian",
  "Locales.ja": "Japanese",
  "Locales.kn": "Kannada",
  "Locales.km": "Khmer",
  "Locales.ko": "Korean",
  "Locales.ky": "Kyrgyz",
  "Locales.lo": "Lao",
  "Locales.lv": "Latvian",
  "Locales.lt": "Lithuanian",
  "Locales.mk": "Macedonian",
  "Locales.ms": "Malay",
  "Locales.ml": "Malayalam",
  "Locales.mr": "Marathi",
  "Locales.mn": "Mongolian",
  "Locales.ne": "Nepali",
  "Locales.no": "Norwegian",
  "Locales.fa": "Persian",
  "Locales.pl": "Polish",
  "Locales.pt-BR": "Portuguese (Brazil)",
  "Locales.pt-PT": "Portuguese (Portugal)",
  "Locales.pt": "Portuguese",
  "Locales.ro": "Romanian",
  "Locales.ru": "Russian",
  "Locales.sr": "Serbian",
  "Locales.si": "Sinhala",
  "Locales.sk": "Slovak",
  "Locales.sl": "Slovenian",
  "Locales.es-419": "Spanish (Latin America)",
  "Locales.es-ES": "Spanish (Spain)",
  "Locales.es": "Spanish",
  "Locales.sw": "Swahili",
  "Locales.sv": "Swedish",
  "Locales.ta": "Tamil",
  "Locales.te": "Telugu",
  "Locales.th": "Thai",
  "Locales.tr": "Turkish",
  "Locales.uk": "Ukrainian",
  "Locales.vi": "Vietnamese",
  "Locales.zu": "Zulu",

  "Territory.AC": "Ascension Island",
  "Territory.AD": "Andorra",
  "Territory.AE": "United Arab Emirates",
  "Territory.AF": "Afghanistan",
  "Territory.AG": "Antigua & Barbuda",
  "Territory.AI": "Anguilla",
  "Territory.AL": "Albania",
  "Territory.AM": "Armenia",
  "Territory.AO": "Angola",
  "Territory.AQ": "Antarctica",
  "Territory.AR": "Argentina",
  "Territory.AS": "American Samoa",
  "Territory.AT": "Austria",
  "Territory.AU": "Australia",
  "Territory.AW": "Aruba",
  "Territory.AX": "Åland Islands",
  "Territory.AZ": "Azerbaijan",
  "Territory.BA": "Bosnia & Herzegovina",
  "Territory.BB": "Barbados",
  "Territory.BD": "Bangladesh",
  "Territory.BE": "Belgium",
  "Territory.BF": "Burkina Faso",
  "Territory.BG": "Bulgaria",
  "Territory.BH": "Bahrain",
  "Territory.BI": "Burundi",
  "Territory.BJ": "Benin",
  "Territory.BL": "St. Barthélemy",
  "Territory.BM": "Bermuda",
  "Territory.BN": "Brunei",
  "Territory.BO": "Bolivia",
  "Territory.BQ": "Caribbean Netherlands",
  "Territory.BR": "Brazil",
  "Territory.BS": "Bahamas",
  "Territory.BT": "Bhutan",
  "Territory.BV": "Bouvet Island",
  "Territory.BW": "Botswana",
  "Territory.BY": "Belarus",
  "Territory.BZ": "Belize",
  "Territory.CA": "Canada",
  "Territory.CC": "Cocos (Keeling) Islands",
  "Territory.CD": "Congo - Kinshasa",
  "Territory.CF": "Central African Republic",
  "Territory.CG": "Congo - Brazzaville",
  "Territory.CH": "Switzerland",
  "Territory.CI": "Côte d’Ivoire",
  "Territory.CK": "Cook Islands",
  "Territory.CL": "Chile",
  "Territory.CM": "Cameroon",
  "Territory.CN": "China",
  "Territory.CO": "Colombia",
  "Territory.CP": "Clipperton Island",
  "Territory.CR": "Costa Rica",
  "Territory.CU": "Cuba",
  "Territory.CV": "Cape Verde",
  "Territory.CW": "Curaçao",
  "Territory.CX": "Christmas Island",
  "Territory.CY": "Cyprus",
  "Territory.CZ": "Czechia",
  "Territory.DE": "Germany",
  "Territory.DG": "Diego Garcia",
  "Territory.DJ": "Djibouti",
  "Territory.DK": "Denmark",
  "Territory.DM": "Dominica",
  "Territory.DO": "Dominican Republic",
  "Territory.DZ": "Algeria",
  "Territory.EA": "Ceuta & Melilla",
  "Territory.EC": "Ecuador",
  "Territory.EE": "Estonia",
  "Territory.EG": "Egypt",
  "Territory.EH": "Western Sahara",
  "Territory.ER": "Eritrea",
  "Territory.ES": "Spain",
  "Territory.ET": "Ethiopia",
  "Territory.EU": "European Union",
  "Territory.EZ": "Eurozone",
  "Territory.FI": "Finland",
  "Territory.FJ": "Fiji",
  "Territory.FK": "Falkland Islands",
  "Territory.FM": "Micronesia",
  "Territory.FO": "Faroe Islands",
  "Territory.FR": "France",
  "Territory.GA": "Gabon",
  "Territory.GB": "United Kingdom",
  "Territory.GD": "Grenada",
  "Territory.GE": "Georgia",
  "Territory.GF": "French Guiana",
  "Territory.GG": "Guernsey",
  "Territory.GH": "Ghana",
  "Territory.GI": "Gibraltar",
  "Territory.GL": "Greenland",
  "Territory.GM": "Gambia",
  "Territory.GN": "Guinea",
  "Territory.GP": "Guadeloupe",
  "Territory.GQ": "Equatorial Guinea",
  "Territory.GR": "Greece",
  "Territory.GS": "South Georgia & South Sandwich Islands",
  "Territory.GT": "Guatemala",
  "Territory.GU": "Guam",
  "Territory.GW": "Guinea-Bissau",
  "Territory.GY": "Guyana",
  "Territory.HK": "Hong Kong",
  "Territory.HM": "Heard & McDonald Islands",
  "Territory.HN": "Honduras",
  "Territory.HR": "Croatia",
  "Territory.HT": "Haiti",
  "Territory.HU": "Hungary",
  "Territory.IC": "Canary Islands",
  "Territory.ID": "Indonesia",
  "Territory.IE": "Ireland",
  "Territory.IL": "Israel",
  "Territory.IM": "Isle of Man",
  "Territory.IN": "India",
  "Territory.IO": "British Indian Ocean Territory",
  "Territory.IQ": "Iraq",
  "Territory.IR": "Iran",
  "Territory.IS": "Iceland",
  "Territory.IT": "Italy",
  "Territory.JE": "Jersey",
  "Territory.JM": "Jamaica",
  "Territory.JO": "Jordan",
  "Territory.JP": "Japan",
  "Territory.KE": "Kenya",
  "Territory.KG": "Kyrgyzstan",
  "Territory.KH": "Cambodia",
  "Territory.KI": "Kiribati",
  "Territory.KM": "Comoros",
  "Territory.KN": "St. Kitts & Nevis",
  "Territory.KP": "North Korea",
  "Territory.KR": "South Korea",
  "Territory.KW": "Kuwait",
  "Territory.KY": "Cayman Islands",
  "Territory.KZ": "Kazakhstan",
  "Territory.LA": "Laos",
  "Territory.LB": "Lebanon",
  "Territory.LC": "St. Lucia",
  "Territory.LI": "Liechtenstein",
  "Territory.LK": "Sri Lanka",
  "Territory.LR": "Liberia",
  "Territory.LS": "Lesotho",
  "Territory.LT": "Lithuania",
  "Territory.LU": "Luxembourg",
  "Territory.LV": "Latvia",
  "Territory.LY": "Libya",
  "Territory.MA": "Morocco",
  "Territory.MC": "Monaco",
  "Territory.MD": "Moldova",
  "Territory.ME": "Montenegro",
  "Territory.MF": "St. Martin",
  "Territory.MG": "Madagascar",
  "Territory.MH": "Marshall Islands",
  "Territory.MK": "North Macedonia",
  "Territory.ML": "Mali",
  "Territory.MM": "Myanmar (Burma)",
  "Territory.MN": "Mongolia",
  "Territory.MO": "Macao SAR China",
  "Territory.MP": "Northern Mariana Islands",
  "Territory.MQ": "Martinique",
  "Territory.MR": "Mauritania",
  "Territory.MS": "Montserrat",
  "Territory.MT": "Malta",
  "Territory.MU": "Mauritius",
  "Territory.MV": "Maldives",
  "Territory.MW": "Malawi",
  "Territory.MX": "Mexico",
  "Territory.MY": "Malaysia",
  "Territory.MZ": "Mozambique",
  "Territory.NA": "Namibia",
  "Territory.NC": "New Caledonia",
  "Territory.NE": "Niger",
  "Territory.NF": "Norfolk Island",
  "Territory.NG": "Nigeria",
  "Territory.NI": "Nicaragua",
  "Territory.NL": "Netherlands",
  "Territory.NO": "Norway",
  "Territory.NP": "Nepal",
  "Territory.NR": "Nauru",
  "Territory.NU": "Niue",
  "Territory.NZ": "New Zealand",
  "Territory.OM": "Oman",
  "Territory.PA": "Panama",
  "Territory.PE": "Peru",
  "Territory.PF": "French Polynesia",
  "Territory.PG": "Papua New Guinea",
  "Territory.PH": "Philippines",
  "Territory.PK": "Pakistan",
  "Territory.PL": "Poland",
  "Territory.PM": "St. Pierre & Miquelon",
  "Territory.PN": "Pitcairn Islands",
  "Territory.PR": "Puerto Rico",
  "Territory.PS": "Palestinian Territories",
  "Territory.PT": "Portugal",
  "Territory.PW": "Palau",
  "Territory.PY": "Paraguay",
  "Territory.QA": "Qatar",
  "Territory.QO": "Outlying Oceania",
  "Territory.RE": "Réunion",
  "Territory.RO": "Romania",
  "Territory.RS": "Serbia",
  "Territory.RU": "Russia",
  "Territory.RW": "Rwanda",
  "Territory.SA": "Saudi Arabia",
  "Territory.SB": "Solomon Islands",
  "Territory.SC": "Seychelles",
  "Territory.SD": "Sudan",
  "Territory.SE": "Sweden",
  "Territory.SG": "Singapore",
  "Territory.SH": "St. Helena",
  "Territory.SI": "Slovenia",
  "Territory.SJ": "Svalbard & Jan Mayen",
  "Territory.SK": "Slovakia",
  "Territory.SL": "Sierra Leone",
  "Territory.SM": "San Marino",
  "Territory.SN": "Senegal",
  "Territory.SO": "Somalia",
  "Territory.SR": "Suriname",
  "Territory.SS": "South Sudan",
  "Territory.ST": "São Tomé & Príncipe",
  "Territory.SV": "El Salvador",
  "Territory.SX": "Sint Maarten",
  "Territory.SY": "Syria",
  "Territory.SZ": "Eswatini",
  "Territory.TA": "Tristan da Cunha",
  "Territory.TC": "Turks & Caicos Islands",
  "Territory.TD": "Chad",
  "Territory.TF": "French Southern Territories",
  "Territory.TG": "Togo",
  "Territory.TH": "Thailand",
  "Territory.TJ": "Tajikistan",
  "Territory.TK": "Tokelau",
  "Territory.TL": "Timor-Leste",
  "Territory.TM": "Turkmenistan",
  "Territory.TN": "Tunisia",
  "Territory.TO": "Tonga",
  "Territory.TR": "Turkey",
  "Territory.TT": "Trinidad & Tobago",
  "Territory.TV": "Tuvalu",
  "Territory.TW": "Taiwan",
  "Territory.TZ": "Tanzania",
  "Territory.UA": "Ukraine",
  "Territory.UG": "Uganda",
  "Territory.UM": "U.S. Outlying Islands",
  "Territory.UN": "United Nations",
  "Territory.US": "United States",
  "Territory.UY": "Uruguay",
  "Territory.UZ": "Uzbekistan",
  "Territory.VA": "Vatican City",
  "Territory.VC": "St. Vincent & Grenadines",
  "Territory.VE": "Venezuela",
  "Territory.VG": "British Virgin Islands",
  "Territory.VI": "U.S. Virgin Islands",
  "Territory.VN": "Vietnam",
  "Territory.VU": "Vanuatu",
  "Territory.WF": "Wallis & Futuna",
  "Territory.WS": "Samoa",
  "Territory.XA": "Pseudo-Accents",
  "Territory.XB": "Pseudo-Bidi",
  "Territory.XK": "Kosovo",
  "Territory.YE": "Yemen",
  "Territory.YT": "Mayotte",
  "Territory.ZA": "South Africa",
  "Territory.ZM": "Zambia",
  "Territory.ZW": "Zimbabwe",
  "Territory.ZZ": "Unknown Region"
}
